/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import {
  Checkbox, ConfigProvider, DatePicker, DatePickerProps, Divider, Input, Select, Space, Upload, UploadFile, UploadProps,
} from 'antd';
import './InstantComparison.css';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcCross from '@ingka/ssr-icon/paths/cross';
import IcDelete from '@ingka/ssr-icon/paths/trash-can';
import IcImage from '@ingka/ssr-icon/paths/image-stack';
import SSRIcon from '@ingka/ssr-icon';
import Button from '@ingka/button';
import Switch from '@ingka/switch';
import RadioButton from '@ingka/radio-button';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Text from '@ingka/text';
import FormField from '@ingka/form-field';
import TextArea from '@ingka/text-area';
import InputField from '@ingka/input-field';
import ListIcon from '@ingka/ssr-icon/paths/list';
import type { RcFile } from 'antd/es/upload/interface';
import Dragger from 'antd/lib/upload/Dragger';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import SelectSkapa, { Option as OptionSkapa } from '@ingka/select';
import AppBarIcons from '../../../common/components/appBarIcons/AppBarIcons';
import { useTranslate } from '../../../common/services/translationService.js';
import useCallApi from '../../../common/services/apiService.js';
import flags from '../../../common/services/flags';
import { RetailUnit } from '../createProject/retailUnitsTab/RetailUnits';
import styles from './InstantComparison.module.css';
import NumberInput from '../../../common/components/inputs/NumberInput';
import PriceInput from '../../../common/components/inputs/PriceInput';
import {
  convertToBase64,
  getBase64,
  isIos, isNullEmptyOrUndefined, isNullEmptyUndefinedOrZero,
  isRu,
  isSu,
  isValidUrl, projectType, userData, userRu, userType,
} from '../../../common/services/commonService.js';
import {
  appRoutes,
  checkInvalidCharRegex,
  checkValidCharRegex,
  checkZeroRegex,
  ComparisonTextArea,
  ComparisonTextAreaProps,
  compNoOfPieces,
  pieces,
  uploadFileTypes,
} from '../../../common/constants/constant';
import ConnectItemPanel from './connectItemPanel/ConnectItemPanel';
import CloseModal from '../../../common/components/closeModal/CloseModal';
import { Item } from '../createProject/items/Items';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import {
  fetchLookupEnums, getQualityCheckCount,
} from '../../../common/services/commonApi.js';
import { offlineData } from '../../../common/configs/offlineData';
import ProjectItemPanel from './projectItemPanel/ProjectItemPanel';
import { ProjectType } from '../dashboard/Dashboard';
import QuantityInput from '../../../common/components/inputs/QuantityInput';
import ImagePreviewModal from './imagePreview/ImagePreviewModal';
import RenderEmpty from '../../../common/components/renderEmpty/RenderEmpty';

const { Option } = Select;

interface SectionTitleProps {
  sectionTitle: string;
  customClass?: string | null;
  optional?: boolean;
}

const SectionTitle = ({
  sectionTitle, customClass, optional,
}: SectionTitleProps) => (
  <div className={styles.sectionTitleWrapper}>
    <span className={`${styles.sectionTitle} ${customClass}`}>{sectionTitle}</span>
    {optional ? (
      <span className={styles.optionalText}>(Optional)</span>
    ) : null}
  </div>
);

SectionTitle.defaultProps = {
  customClass: null,
  optional: false,
};

const SectionDescription = ({ sectionDescription }: { sectionDescription: string }) => (
  <span className={styles.sectionDescription}>{sectionDescription}</span>
);

export interface Units {
  system?: string,
  code: string,
  unit: string
}

const InstantComparison = () => {
  const location: any = useLocation();
  const [isInstantCompare, setIsInstantCompare] = useState(true);
  const [projectId, setProjectId] = useState(null);
  const currencyList = useAppSelector((state) => state?.createProjReducer?.currencyList);
  const [retailUnits, setRetailUnits] = useState<RetailUnit[]>([]);
  const [showRetailUnit, setShowRetailUnit] = useState<boolean>(false);
  const [showTimeRestricted, setShowTimeRestricted] = useState<boolean>(false);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [itemQuantity, setItemQuantity] = useState<number>();
  const [quantityError, setQuantityError] = useState<boolean>(false);
  const [competitorPrice, setCompetitorPrice] = useState<string>('');
  const [regularPrice, setRegularPrice] = useState<string>();
  const [unknownRegularPrice, setUnknownRegularPrice] = useState<boolean>(false);
  const [unknownStartDate, setUnknownStartDate] = useState<boolean>(false);
  const [unknownEndDate, setUnknownEndDate] = useState<boolean>(false);
  const [itemQuality, setItemQuality] = useState<string>();
  const [itemFunctionality, setItemFunctionality] = useState<string>();
  const [itemAssembled, setItemAssembled] = useState<string>();
  const [openPricePopupSave, setOpenPricePopupSave] = useState<boolean>(false);
  const [configuredPrice, setConfiguredPrice] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [currency, setCurrency] = useState();
  const dispatch = useAppDispatch();
  const labelQuantity = useTranslate('inst.comp.quantity');
  const textAreaErr = useTranslate('error.textAreaInvalid');
  const labelUnits = useTranslate('inst.comp.units');
  const fileUploadErr = useTranslate('fileUpload.format.error');
  const labelCurrentPrice = useTranslate('inst.comp.current_price');
  const labelPrice = useTranslate('inst.comp.price');
  const labelStartDate = useTranslate('tab.details.start_date');
  const labelEndDate = useTranslate('tab.details.end_date');
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<number | string>();
  const [selectedCompetitor, setSelectedCompetitor] = useState<string | number>();
  const [competitorList, setCompetitorsList] = useState<any>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isFindCompare, setIsFindCompare] = useState(false);
  const disableAllFields = selectedRU === null;
  const [disableAllEditFields, setDisableAllEditFields] = useState<boolean>(true);
  const [material, setMaterial] = useState<string>();
  const [sustainability, setSustainability] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [competitorItemName, setCompetitorItemName] = useState<string>();
  const [competitorItemUrl, setCompetitorItemUrl] = useState<string>();
  const [openConnectItemPanel, setOpenConnectItemPanel] = useState<boolean>(false);
  const [openProjectItemPanel, setOpenProjectItemPanel] = useState<boolean>(false);
  const [connectedItem, setConnectedItem] = useState<Item | any | null>(null);
  const [openConnectItemModal, setOpenConnectItemModal] = useState<boolean>(false);
  const [addNewCompFlag, setAddNewCompFlag] = useState<boolean>(false);
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const [urlError, setUrlError] = useState<boolean>(false);
  const [urlThreatErr, setUrlThreatErr] = useState<boolean>(false);
  const [unitListDisplay, setUnitListDisplay] = useState<Array<Units>>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [measuringSystem, setMeasuringSystem] = useState<Array<string>>([]);
  const [foodHFBs, setFoodHFBs] = useState<Array<string>>([]);
  const [isFoodHfb, setIsFoodHfb] = useState<boolean>(false);
  const [disablePieces, setDisablePieces] = useState<boolean>(false);
  const [noCompData, setNoCompData] = useState<boolean>(false);
  const [fetchFoodFlag, setFetchFoodFlag] = useState<boolean>(false);
  const [ikeaPriceObject, setIkeaPriceObject] = useState<any>(null);
  const navigate = useNavigate();
  const retailUnitTitle = useTranslate('inst.comp.retailUnitTitle');
  const retailUnitDescription = useTranslate('inst.comp.retailUnitDescription');
  const retailUnitCurrencyDescription = useTranslate('inst.comp.retailUnitCurrencyDescription');
  const retailUnitPlaceholder = useTranslate('inst.comp.retailUnitPlaceholder');
  const numberofPiecesTitle = useTranslate('inst.comp.numberofPiecesTitle');
  const numberofPiecesDescription = useTranslate('inst.comp.numberofPiecesDescription');
  const onePieceText = useTranslate('inst.comp.onePieceText');
  const competitorTitle = useTranslate('inst.comp.competitorTitle');
  const competitorPlaceholder = useTranslate('inst.comp.competitorPlaceholder');
  const competitorPriceTitle = useTranslate('inst.comp.competitorPrice');
  const switchText = useTranslate('inst.comp.switchText');
  const switchValue = useTranslate('inst.comp.switchValue');
  const regularPriceTitle = useTranslate('inst.comp.regularPrice');
  const radioButtonText = useTranslate('inst.comp.radioButtonText');
  const radioButtonValue = useTranslate('inst.comp.radioButtonValue');
  const restrictedOfferTimeStart = useTranslate('inst.comp.restrictedOfferTimeStart');
  const compDate = useTranslate('inst.comp.date');
  const restrictedOfferTimeEnd = useTranslate('inst.comp.restrictedOfferTimeEnd');
  const qualityDescription = useTranslate('inst.comp.qualityDescription');
  const itemAsembleTitle = useTranslate('inst.comp.itemAsembleTitle');
  const competitorName = useTranslate('inst.comp.competitorName');
  const competitorValue = useTranslate('inst.comp.competitorValue');
  const competitorUrlTitle = useTranslate('inst.comp.competitorUrlTitle');
  const competitorUrlValue = useTranslate('inst.comp.competitorUrlValue');
  const photoTitle = useTranslate('inst.comp.photoTitle');
  const photoDescription = useTranslate('inst.comp.photoDescription');
  const photoTextOne = useTranslate('inst.comp.photoTextOne');
  const photoTextTwo = useTranslate('inst.comp.photoTextTwo');
  const PhotoTextThree = useTranslate('inst.comp.PhotoTextThree');
  const addingComparison = useTranslate('inst.comp.addingComparison');
  const comparisonAdded = useTranslate('inst.comp.comparisonAdded');
  const noItemConnected = useTranslate('inst.comp.noItemConnected');
  const connectIKEA = useTranslate('inst.comp.connectIKEA');
  const seeConnectIkeaItem = useTranslate('inst.comp.seeConnectIkeaItem');
  const saveComparisonTitle = useTranslate('inst.comp.saveComparison');
  const updateComparisonTitle = useTranslate('inst.comp.updateComparison');
  const invalidUrlMsg = useTranslate('inst.comp.invalidUrl');
  const threatUrlMsg = useTranslate('inst.comp.threatUrl');
  const [searchCompValue, setSearchCompValue] = useState('');
  const [comparsionId, setComparsionId] = useState<number | string>();
  const [openDeleteComparison, setopenDeleteComparison] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<Item | any | null>(null);
  const [selectedRUCode, setSelectedRUCode] = useState<string>();
  const [isFCEdit, setIsFCEdit] = useState<boolean>(false);
  const [isFCDelete, setIsFCDelete] = useState<boolean>(false);
  const [selectedFcCompetitors, setSelectedFcCompetitors] = useState<[]>([]);
  const projectName = useTranslate('inst.comp.projectName');
  const projectStartDate = useTranslate('inst.comp.startDate');
  const projectEndDate = useTranslate('inst.comp.endDate');
  const projectComparedOn = useTranslate('inst.comp.comparedOn');
  const projectUpdatedOn = useTranslate('inst.comp.updatedOn');
  const comparisonSaveError = useTranslate('comparison.save.error');
  const ikeaPiecesText = useTranslate('inst.comp.ikeaPiecesText');
  const [imageToken, setImageToken] = useState<any>();
  const [imgPreviewOpen, setImgPreviewOpen] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();
  const [previewImageList, setPreviewImageList] = useState<any[]>([]);
  const [imageListData, setImageListData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any>();
  const { post, get } = useCallApi();
  const isSend = location.state?.isSend || false;
  const showProjData = location.state?.showProjDetails || false;
  const priceRef: any = useRef(null);
  const piecesRef: any = useRef(null);
  const [priceFocus, setPriceFocus] = useState<boolean>(false);
  const [pieceFocus, setPieceFocus] = useState<boolean>(false);
  const [qtyFocus, setQtyFocus] = useState<boolean>(false);
  const [piaItemPrice, setPiaItemPrice] = useState<any>();
  const [deletedImages, setDeletedImages] = useState<any[]>([]);

  const fetchUnitCode = () => {
    if (isFindCompare === true) {
      return isSu() ? userRu() : selectedRUCode;
    }
    return selectedRU?.ruCode;
  };

  const initPayload = {
    CompPriceUnitText: null,
    CompPriceUnitValue: null,
    ComparisonId: null,
    CompetitorId: null,
    CompetitorName: undefined,
    CriteriaOptionList: [
      { CriteriaId: '20', CriteriaValue: undefined },
      { CriteriaId: '30', CriteriaValue: '' },
      { CriteriaId: '210', CriteriaValue: 'false' },
      { CriteriaId: '60', CriteriaValue: undefined },
      { CriteriaId: '40', CriteriaValue: undefined },
      { CriteriaId: '50', CriteriaValue: undefined },
      { CriteriaId: '70', CriteriaValue: null },
      { CriteriaId: '80', CriteriaValue: null },
      { CriteriaId: '90', CriteriaValue: null },
      { CriteriaId: '170', CriteriaValue: undefined },
      { CriteriaId: '180', CriteriaValue: undefined },
      { CriteriaId: '190', CriteriaValue: undefined },
      { CriteriaId: '200', CriteriaValue: undefined },
      { CriteriaId: '220', CriteriaValue: undefined },
      { CriteriaId: '420', CriteriaValue: null },
      { CriteriaId: '320', CriteriaValue: null }],
    CurrencyCode: null,
    IkeaPriceUnitText: null,
    IkeaPriceUnitValue: null,
    ImageList: [],
    IsFoodHfb: 0,
    IsInstantCompare: 1,
    IsMobile: 0,
    ItemNo: null,
    ItemType: null,
    NoCompetition: 0,
    PaNo: null,
    PriceUnitType: null,
    PriceUnitTypeNo: null,
    ProjectId: null,
    ProjectType: projectType(),
    UnitCode: fetchUnitCode(),
    UpdateUserId: isFindCompare === true ? userData()?.userId : null,
    UserId: userData()?.userId,
  };

  const setRuData = (ruData:any) => {
    const ruCode = location.state?.ruCode;
    const ruDat = ruData?.filter((option: any) => option.ruCode === ruCode) as any;
    ruCode && setSelectedRU(ruDat[0] || null);
    setRetailUnits(ruData);
  };

  const getRetailUnits = () => {
    get('retail-units', (res:any) => setRuData(res));
  };

  const getCompetitorsList = (val: any) => {
    const compPayload = {
      UnitCode: val,
      ProjectType: userType(),
    };
    post('competitors', compPayload, (res:any) => setCompetitorsList(res?.competitorInfoViewDto || []));
  };

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const navigateToFC = () => {
    navigate(
      appRoutes.FC,
      {
        state: {
          isICDetails: true,
          fcCompetitors: selectedFcCompetitors,
        },
      },
    );
  };

  const navToDash = () => {
    navigate(
      appRoutes.dashboard,
      {
        state: {
          dashboardFilters: true,
        },
      },
    );
  };

  const editItemCriteria = (field: any | undefined) => {
    switch (field) {
      case '10':
        return 'better';
      case '20':
        return 'equal';
      case '30':
        return 'worse';
      default:
        return undefined;
    }
  };

  const editItemAssemblyYorN = (field: string | null) => {
    if (field === '80') {
      return 'true';
    }
    if (field === '100') {
      return 'false';
    }
    return undefined;
  };

  const setCurrencyData = (selectedVal: any) => {
    if (currencyList.length > 0) {
      const currencyCode = currencyList.find((item: any) => item.key === selectedVal);
      setCurrency(currencyCode?.value);
    }
  };

  const isPieces = () => selectedUnit === pieces.code;

  const onUnitSelect = (val: any) => {
    if (itemQuantity?.toString().length !== 0 && !isPieces() && val === pieces.code && itemQuantity?.toString().includes('.')) {
      setQuantityError(true);
      setItemQuantity(undefined);
    } else {
      setQuantityError(false);
    }
    setSelectedUnit(val);
  };

  const selectNonFoodHfb = () => {
    setUnitListDisplay([pieces]);
    onUnitSelect(pieces.code);
    setDisablePieces(true);
  };

  useEffect(() => {
    setProjectId(location.state?.projectId);
    setIsInstantCompare(location.state?.isInstantCompare);
    setIsFindCompare(location.state?.isFindCompare);
    setComparsionId(location.state?.comparisonId);
    setProjectDetails(location.state?.projectDetail);
    setIsFCEdit(location.state?.isEdit);
    setIsFCDelete(location.state?.isDelete);
    setSelectedFcCompetitors(location.state?.selectedCompetitors);
    getSasToken();
    const comparison = location.state?.comparisonDetail;
    const item = location.state?.projectItem;
    if (!isNullEmptyOrUndefined(item)) {
      const itemBuild = {
        canAddComparison: item?.comparisonItemBasicViewEntity?.canAddComparison,
        currency: item?.comparisonItemBasicViewEntity?.currency,
        deadlineDate: item?.comparisonItemBasicViewEntity?.deadlineDate,
        endDate: item?.comparisonItemBasicViewEntity?.endDate,
        itemEnglishName: item?.comparisonItemBasicViewEntity?.itemEnglishName,
        itemImage: item?.comparisonItemBasicViewEntity?.itemImage,
        itemName: item?.comparisonItemBasicViewEntity?.itemName,
        itemNo: item?.comparisonItemBasicViewEntity?.itemNo,
        itemState: item?.comparisonItemBasicViewEntity?.itemState,
        itemType: item?.comparisonItemBasicViewEntity?.itemType,
        localRate: item?.comparisonItemBasicViewEntity?.localRate,
        hfbNo: item?.comparisonItemBasicViewEntity?.hfbNo,
        paNo: item?.comparisonItemBasicViewEntity?.paNo,
        piaFactsLink: item?.comparisonItemBasicViewEntity?.piaFactsLink,
        startDate: item?.comparisonItemBasicViewEntity?.startDate,
        metricMeasureList: item?.metricMeasureList,
        materialMeasureList: item?.materialMeasureList,
        imperialMeasureList: item?.imperialMeasureList,
        comparisonItemImageViewEntity: item?.comparisonItemImageViewEntity,
        priceInclTax: item?.priceInclTax,
        isBti: item?.comparisonItemBasicViewEntity?.isBti,
        ikeaMultiPack: item?.comparisonItemBasicViewEntity?.ikeaMultiPack,
        comment: item?.comparisonItemBasicViewEntity?.comment,
      };
      !isNullEmptyOrUndefined(itemBuild.itemNo) && setConnectedItem(itemBuild);
    }
    if (!isNullEmptyOrUndefined(comparison)) {
      const ruCode = location.state?.ruCode;
      getCompetitorsList(ruCode);
      setSelectedRUCode(ruCode);
      const ruDataVal = retailUnits?.filter((option: any) => option.ruCode === ruCode) as any;
      retailUnits?.length !== 0 && setSelectedRU(ruDataVal[0] || null);
      setSelectedCompetitor(location.state?.competitorName);
      initPayload.CompetitorName = location.state?.competitorName;
      setSelectedCompetitorId(comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 10)?.criteriaValue);
      setCurrency(comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 340)?.criteriaValue);
      const competitorPriceVal = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 30)?.criteriaValue;
      setCompetitorPrice(competitorPriceVal);
      initPayload.CriteriaOptionList[1].CriteriaValue = competitorPriceVal;
      const isTimeRestricted: boolean = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 210)?.criteriaValue === '50';
      setShowTimeRestricted(isTimeRestricted);
      const edtiRegularPrice = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 60)?.criteriaValue;
      edtiRegularPrice && setRegularPrice(edtiRegularPrice);
      initPayload.CriteriaOptionList[3].CriteriaValue = edtiRegularPrice || undefined;
      isTimeRestricted && setUnknownRegularPrice(isNullEmptyOrUndefined(edtiRegularPrice));
      const editStartDate = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 40)?.criteriaValue;
      editStartDate && setStartDate(dayjs(editStartDate, 'YYYY-MM-DD'));
      initPayload.CriteriaOptionList[4].CriteriaValue = editStartDate || undefined;
      isTimeRestricted && setUnknownStartDate(isNullEmptyOrUndefined(editStartDate));
      const editEndDate = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 50)?.criteriaValue;
      editEndDate && setEndDate(dayjs(editEndDate, 'YYYY-MM-DD'));
      initPayload.CriteriaOptionList[5].CriteriaValue = editEndDate || undefined;
      isTimeRestricted && setUnknownEndDate(isNullEmptyOrUndefined(editEndDate));
      const editItemQuality = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 70)?.criteriaValue;
      setItemQuality(editItemCriteria(editItemQuality));
      initPayload.CriteriaOptionList[6].CriteriaValue = editItemQuality;
      const editItemFunctionality = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 80)?.criteriaValue;
      setItemFunctionality(editItemCriteria(editItemFunctionality));
      initPayload.CriteriaOptionList[7].CriteriaValue = editItemFunctionality;
      const editItemAssembled = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 90)?.criteriaValue;
      setItemAssembled(editItemAssemblyYorN(editItemAssembled));
      initPayload.CriteriaOptionList[8].CriteriaValue = editItemAssembled;
      const compComment = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 170)?.criteriaValue || '';
      setComment(compComment);
      initPayload.CriteriaOptionList[9].CriteriaValue = compComment;
      const compSustainability = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 180)?.criteriaValue || '';
      setSustainability(compSustainability);
      initPayload.CriteriaOptionList[10].CriteriaValue = compSustainability;
      const compMaterial = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 190)?.criteriaValue || '';
      setMaterial(compMaterial);
      initPayload.CriteriaOptionList[11].CriteriaValue = compSustainability;
      const compItemName = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 200)?.criteriaValue || '';
      setCompetitorItemName(compItemName);
      initPayload.CriteriaOptionList[12].CriteriaValue = compItemName;
      const compItemUrl = comparison?.comparisonDetails.find((option: any) => option.criteriaKey === 220)?.criteriaValue || '';
      setCompetitorItemUrl(compItemUrl);
      initPayload.CriteriaOptionList[13].CriteriaValue = compItemUrl;
      setIsFoodHfb(comparison?.comparisonDetailViewInfoDto?.isFoodHfb || false);
      const compUnit = comparison?.comparisonDetailViewInfoDto?.compPriceUnitText;
      if (isNullEmptyOrUndefined(compUnit) || compUnit === pieces.code) {
        const compMultipack = comparison?.comparisonDetailViewInfoDto?.multipack;
        setItemQuantity(compMultipack);
        initPayload.CriteriaOptionList[0].CriteriaValue = compMultipack;
      } else {
        const compItemQty = comparison?.comparisonDetailViewInfoDto?.compPriceUnitValue;
        setItemQuantity(compItemQty);
        initPayload.CriteriaOptionList[0].CriteriaValue = compItemQty;
      }
      setEditData(initPayload);
    }
    if (isIos()) {
      getRetailUnits();
      setShowRetailUnit(true);
    } else {
      offlineData.getItem('selectedRetailUnit').then((data: any) => {
        setSelectedRU(data);
        getCompetitorsList(data?.ruCode);
      });
    }
    if (currencyList.length !== 0 && !isIos()) {
      setCurrencyData(userRu());
    } else {
      fetchLookupEnums(get);
    }
    return () => {
      ComparisonTextArea.material.textErr = false;
      ComparisonTextArea.sustainability.textErr = false;
      ComparisonTextArea.comment.textErr = false;
    };
  }, []);

  useEffect(() => {
    const fcComparison = (location.state?.comparisonDetail);
    const fcImages = fcComparison?.comparisonImageDetails;
    const fcUploadImages: UploadFile[] = [];
    fcImages?.forEach((image: any, index: string) => {
      const imageURL = image.imageUrl.concat(imageToken?.sasToken);
      const uploadFile: UploadFile = {
        fileName: image.FileName,
        name: image.FileName,
        uid: `${image.comparisonId}-${index}`,
        url: imageURL,
      };
      fcUploadImages.push(uploadFile);
    });
    setFileList(fcUploadImages);
  }, [imageToken]);

  const toggleConnectItemPanel = () => {
    setOpenConnectItemPanel(!openConnectItemPanel);
  };

  const toggleProjectItemPanel = () => {
    setOpenProjectItemPanel(!openProjectItemPanel);
  };

  const onRetailUnitChange = (value: number) => {
    const selectedVal = retailUnits[value];
    setSelectedRU({ ...selectedVal, ruVal: value });
    setCurrencyData(selectedVal?.ruCode);
    getCompetitorsList(selectedVal?.ruCode);
  };

  const onOnePieceClick = () => {
    setItemQuantity(1);
    quantityError === true && setQuantityError(false);
  };

  const showToast = () => {
    if (isFindCompare === true) {
      return 'Comparison has been updated';
    }
    return 'Comparison has been saved';
  };

  const saveComparison = (payload: any) => {
    dispatch(setLoader(true));
    post(
      'save-comparison',
      payload,
      (res:any) => {
        dispatch(setToast({
          openToast: true,
          toastLabel: showToast(),
        }));
        dispatch(setLoader(false));
        getQualityCheckCount(post);
        isFindCompare === true
          ? navigateToFC()
          : isSend === true ? navigate(appRoutes.sentProjectDetails, {
            state: { project: location.state?.selectedProj, type: isRu() ? ProjectType.SENT : ProjectType.ACCEPTED },
          }) : navigate(
            appRoutes.dashboard,
            {
              state: {
                dashboardFilters: true,
              },
            },
          );
      },
      (err:AxiosError) => {
        if (err?.code === 'ERR_NETWORK') {
          setUrlError(true);
          setUrlThreatErr(true);
        }
        dispatch(setLoader(false));
        dispatch(setToast({ openToast: true, toastLabel: comparisonSaveError }));
      },
    );
  };

  const comparisonPhotoDelete = (deleteList: any, saveCompPayload: any) => {
    const imageNames: any[] = [];
    deleteList?.forEach((file: any) => {
      imageNames.push(file?.name);
    });
    const payload = {
      imageNames,
    };
    post('delete-image', payload, (data: any) => {
      const successImage = data?.find((image: any) => image?.isSuccess === true);
      if (!isNullEmptyOrUndefined(successImage)) {
        saveComparison(saveCompPayload);
      } else {
        dispatch(setLoader(false));
        dispatch(setToast({ openToast: true, toastLabel: 'Failed to delete the photo(s)' }));
      }
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: 'Failed to delete the photo(s)' }));
    });
  };

  const syncDeleteImages = (saveCompPayload: any) => {
    if (deletedImages?.length > 0) {
      dispatch(setLoader(true));
      comparisonPhotoDelete(deletedImages, saveCompPayload);
    } else {
      saveComparison(saveCompPayload);
    }
  };

  const itemCriteria = (field: string | undefined) => {
    switch (field) {
      case 'better':
        return '10';
      case 'equal':
        return '20';
      case 'worse':
        return '30';
      default:
        return null;
    }
  };

  const itemAssemblyYorN = (field: string | undefined) => {
    switch (field) {
      case 'true':
        return '80';
      case 'false':
        return '100';
      default:
        return null;
    }
  };

  const isIC = location.state?.isInstantCompare;
  const isFC = location.state?.isFindCompare;

  const isProjectBasedComparison = () => (isNullEmptyOrUndefined(isIC) || isIC === false) && (isNullEmptyOrUndefined(isFC) || isFC === false);

  const isInstantComparison = () => isIC === true && (isNullEmptyOrUndefined(isFC) || isFC === false);

  const isEditComparison = () => (isNullEmptyOrUndefined(isIC) || isIC === false) && isFC === true;

  const editValChanged = () => {
    const item = location.state?.projectItem;
    const itemName = item?.comparisonItemBasicViewEntity?.itemName;
    const ruCode = location.state?.ruCode;
    const compName = location.state?.competitorName;
    if (isEditComparison() && (compName !== selectedCompetitor
      || ruCode !== fetchUnitCode()
      || itemName !== connectedItem?.itemName)) {
      return true;
    }
    return false;
  };

  const buildRuCode = () => {
    if (isSu()) {
      return localStorage.getItem('RuCodeForSu') || '';
    }
    return fetchUnitCode() || '';
  };

  const handleUpload = (payload: object) => {
    const formData = new FormData();
    const fileNames: any = [];
    fileList.forEach((file) => {
      const isOriginFile = !isNullEmptyOrUndefined(file?.originFileObj);
      if (isOriginFile) {
        const lastIndex = file?.name.lastIndexOf('.');
        const replaced = `IMAGE${file?.name.substring(lastIndex)}`;
        formData.append('newImages', file.originFileObj as RcFile, replaced);
      } else {
        const existingImages = {
          FileName: file?.name,
        };
        fileNames.push(existingImages);
      }
    });
    setImageListData(fileNames);
    if (!isNullEmptyOrUndefined(fileNames) && formData.getAll('newImages').length === 0 && !editValChanged()) {
      syncDeleteImages(fileNames.length !== 0 ? { ...payload, ImageList: fileNames } : payload);
    } else {
      if (fileNames.length > 0) {
        fileNames.forEach((image: any) => {
          formData.append('existingImages', image.FileName);
        });
      }
      const itemName = connectedItem?.itemName?.replaceAll('/', '');
      const ItemNameTrimmed = itemName?.replace(/\s+/g, '');
      formData.append('itemName', ItemNameTrimmed || '');
      formData.append('competetorName', selectedCompetitor as any);
      formData.append('retailUnit', buildRuCode());
      dispatch(setLoader(true));
      post('upload-image', formData, (res: []) => {
        const errorFiles = res.filter((file: any) => file?.status?.toString()?.toLowerCase() === 'fail');
        if (errorFiles?.length >= 1) {
          dispatch(setLoader(false));
          dispatch(setToast({ openToast: true, toastLabel: `Malicious file${errorFiles?.length > 1 ? 's' : ''} found while uploading` }));
        } else {
          const imgData = res.map((val: any) => ({ FileName: val.fileName }));
          syncDeleteImages(imgData.length !== 0 ? { ...payload, ImageList: imgData } : payload);
        }
      });
    }
  };

  const isImperialUnit = () => {
    const sys = unitListDisplay.find((unit) => unit.code === selectedUnit)?.system;
    if (sys === 'IMP') return true;
    return false;
  };

  const getIkeaPriceUnitText = () => {
    if (isImperialUnit()) {
      const ikeaPriceText = (ikeaPriceObject?.PriceUnitType === 'Volume' && ikeaPriceObject?.priceUnitTextImperial === 'oz')
        ? 'fl.oz' : ikeaPriceObject?.priceUnitTextImperial || null;
      return ikeaPriceText;
    }
    return ikeaPriceObject?.priceUnitTextMetric || null;
  };

  const getIkeaPriceUnitValue = () => {
    if (isImperialUnit()) {
      return ikeaPriceObject?.priceUnitFactorImperial || null;
    }
    return ikeaPriceObject?.priceUnitFactorMetric || null;
  };

  const buildComparisonPayload = () => {
    const payload = {
      ComparisonId: isFindCompare === true ? comparsionId : null,
      CurrencyCode: isFindCompare === true ? currency : null,
      NoCompetition: 0,
      ProjectType: projectType(),
      IsInstantCompare: isInstantCompare === true ? 1 : 0,
      ProjectId: projectId || null,
      UpdateUserId: isFindCompare === true ? userData()?.userId : null,
      UserId: userData()?.userId,
      UnitCode: fetchUnitCode(),
      CompetitorId: addNewCompFlag !== true ? Number(selectedCompetitorId) || null : null,
      CompetitorName: selectedCompetitor || null,
      ImageList: [],
      ItemNo: connectedItem ? connectedItem.itemNo : null,
      ItemType: connectedItem ? connectedItem.itemType : null,
      PaNo: connectedItem ? connectedItem.paNo : null,
      IsFoodHfb: isFoodHfb === true ? 1 : 0,
      PriceUnitTypeNo: ikeaPriceObject?.PriceUnitTypeNo || null,
      PriceUnitType: ikeaPriceObject?.PriceUnitType || null,
      IkeaPriceUnitText: !isPieces() ? getIkeaPriceUnitText() : null,
      IkeaPriceUnitValue: !isPieces() ? getIkeaPriceUnitValue() : null,
      CompPriceUnitText: (isNullEmptyOrUndefined(selectedUnit) || isPieces()) ? null : selectedUnit,
      CompPriceUnitValue: !isPieces() ? Number(itemQuantity) : null,
      IsMobile: 0,
      CriteriaOptionList: [
        { // No of pieces
          CriteriaId: '20',
          CriteriaValue: isPieces() ? itemQuantity?.toString() : null,
        },
        { // Competitor price
          CriteriaId: '30',
          CriteriaValue: competitorPrice?.toString() || null,
        },
        { // Time Restricted Offer
          CriteriaId: '210',
          CriteriaValue: showTimeRestricted.toString(),
        },
        { // TRO - Regular price
          CriteriaId: '60',
          CriteriaValue: regularPrice || null,
        },
        { // TRO - Start date
          CriteriaId: '40',
          CriteriaValue: startDate ? startDate.format('YYYY-MM-DD') : null,
        },
        { // TRO - End date
          CriteriaId: '50',
          CriteriaValue: endDate ? endDate.format('YYYY-MM-DD') : null,
        },
        { // Competitor item quality
          CriteriaId: '70',
          CriteriaValue: itemCriteria(itemQuality),
        },
        { // Competitor item functionality
          CriteriaId: '80',
          CriteriaValue: itemCriteria(itemFunctionality),
        },
        { // Competitor item assembled before use
          CriteriaId: '90',
          CriteriaValue: itemAssemblyYorN(itemAssembled),
        },
        { // Optional - Comment
          CriteriaId: '170',
          CriteriaValue: convertToBase64(comment) || null,
        },
        { // Optional - Sustainability
          CriteriaId: '180',
          CriteriaValue: convertToBase64(sustainability) || null,
        },
        { // Optional - Material
          CriteriaId: '190',
          CriteriaValue: convertToBase64(material) || null,
        },
        { // Optional - Competitor item name
          CriteriaId: '200',
          CriteriaValue: competitorItemName || null,
        },
        { // Optional - Competitor item URL (Internet link)
          CriteriaId: '220',
          CriteriaValue: competitorItemUrl || null,
        },
      ],
    };
    let criteria320 = [{
      CriteriaId: '320',
      CriteriaValue: connectedItem ? piaItemPrice?.toString() || connectedItem?.priceInclTax?.toString() || null : null,
    }];
    if (connectedItem && connectedItem?.itemType && connectedItem?.itemNo) {
      dispatch(setLoader(true));
      const itemPricePayload = {
        ItemNos: [`${connectedItem?.itemType}-${connectedItem?.itemNo.replaceAll('.', '')}`],
        RuCode: isSu() ? localStorage.getItem('RuCodeForSu') : selectedRU?.ruCode,
      };
      post('item-price', itemPricePayload, (value: any) => {
        dispatch(setLoader(false));
        if (value[0]?.priceInclTax) {
          criteria320 = [{
            CriteriaId: '320',
            CriteriaValue: value[0]?.priceInclTax.toString() || null,
          }];
        }
        payload.CriteriaOptionList = [...payload.CriteriaOptionList, ...criteria320];
        fileList.length !== 0 ? handleUpload(payload) : syncDeleteImages(payload);
      }, () => {
        dispatch(setLoader(false));
        payload.CriteriaOptionList = [...payload.CriteriaOptionList, ...criteria320];
        fileList.length !== 0 ? handleUpload(payload) : syncDeleteImages(payload);
      });
    } else {
      payload.CriteriaOptionList = [...payload.CriteriaOptionList, ...criteria320];
      fileList.length !== 0 ? handleUpload(payload) : syncDeleteImages(payload);
    }
  };

  const compareDataChange = (data: any) => {
    if (data.CompetitorName !== selectedCompetitor
      || data.ImageList?.length !== imageListData?.length
      || data.CriteriaOptionList[0].CriteriaValue !== itemQuantity
      || data.CriteriaOptionList[1].CriteriaValue !== competitorPrice
      || data.CriteriaOptionList[3].CriteriaValue !== regularPrice
      || data.CriteriaOptionList[4].CriteriaValue !== startDate?.format('YYYY-MM-DD')
      || data.CriteriaOptionList[5].CriteriaValue !== endDate?.format('YYYY-MM-DD')
      || data.CriteriaOptionList[6].CriteriaValue !== itemCriteria(itemQuality)
      || data.CriteriaOptionList[7].CriteriaValue !== itemCriteria(itemFunctionality)
      || data.CriteriaOptionList[8].CriteriaValue !== itemAssemblyYorN(itemAssembled)
      || data.CriteriaOptionList[9].CriteriaValue !== comment
      || data.CriteriaOptionList[10].CriteriaValue !== sustainability
      || data.CriteriaOptionList[11].CriteriaValue !== material
      || data.CriteriaOptionList[12].CriteriaValue !== competitorItemName
      || data.CriteriaOptionList[13].CriteriaValue !== competitorItemUrl) return false;
    return true;
  };

  const handleCloseModal = () => {
    setDeletedImages([]);
    if (isFindCompare) {
      if (!disableAllEditFields) {
        if (compareDataChange(editData) === false) { setOpenCloseModal(true); } else {
          navigateToFC();
        }
      } else {
        navigateToFC();
      }
    } else if (isIos() && !disableAllFields) {
      setOpenCloseModal(true);
    } else if (!disableAllFields) {
      if (compareDataChange(initPayload) === false) { setOpenCloseModal(true); } else {
        navToDash();
      }
    } else {
      navToDash();
    }
  };

  const handleCloseModalButtons = (type?: number) => {
    if (type === 2) {
      isFindCompare === true
        ? navigateToFC() : isSend ? navigate(appRoutes.sentProjectDetails, {
          state: { project: location.state?.selectedProj, type: isRu() ? ProjectType.SENT : ProjectType.ACCEPTED },
        }) : navigate(
          appRoutes.dashboard,
          {
            state: {
              dashboardFilters: true,
            },
          },
        );
      setOpenCloseModal(false);
    } else {
      setOpenCloseModal(false);
    }
  };

  const resetPriceFieldFocus = () => {
    setPriceFocus(false);
  };

  const scrollToPriceField = () => {
    setTimeout(() => {
      priceRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setPriceFocus(false);
    }, 500);
    setTimeout(() => {
      setPriceFocus(true);
    }, 600);
  };

  const handlePriceModalButtons = (type?: number) => {
    type === 2 && buildComparisonPayload();
    setOpenPricePopupSave(false);
    type !== 2 && scrollToPriceField();
  };

  const checkSavePrerequisits = () => {
    resetPriceFieldFocus();
    if (connectedItem === null) {
      setOpenConnectItemModal(true);
    } else {
      setOpenConnectItemModal(false);
      setOpenPricePopupSave(false);
      buildComparisonPayload();
    }
  };

  const priceValidationFail = () => {
    dispatch(setLoader(false));
    setOpenPricePopupSave(false);
    checkSavePrerequisits();
  };

  const checkCompPriceValid = () => {
    if (competitorPrice && itemQuantity && connectedItem?.itemType && connectedItem?.itemNo) {
      dispatch(setLoader(true));
      const itemPricePayload = {
        ItemNos: [`${connectedItem?.itemType}-${connectedItem?.itemNo.replaceAll('.', '')}`],
        RuCode: isSu() ? localStorage.getItem('RuCodeForSu') : selectedRU?.ruCode,
      };
      post('item-price', itemPricePayload, (value: any) => {
        if (value[0]?.priceInclTax) {
          setPiaItemPrice(value[0]?.priceInclTax);
          const pricePayload = {
            IkeaItemPrice: value[0].priceInclTax,
            CompetitorPrice: competitorPrice,
            CompetitorMultipack: isPieces() ? Number(itemQuantity) : null,
            ItemNo: connectedItem?.itemNo,
            IsFoodHfb: isFoodHfb === true ? 1 : 0,
            IkeaPriceUnitText: isFoodHfb === true ? getIkeaPriceUnitText() : null,
            IkeaPriceUnitValue: isFoodHfb === true ? getIkeaPriceUnitValue() : null,
            CompPriceUnitText: (isNullEmptyOrUndefined(selectedUnit) || isPieces()) ? null : selectedUnit,
            CompPriceUnitValue: !isPieces() ? Number(itemQuantity) : null,
          };
          post('CompetitorItem-DiffPercentage', pricePayload, (val: any) => {
            dispatch(setLoader(false));
            if (val?.isMoreThanConfigured === true || (isPieces() === true && itemQuantity > compNoOfPieces)) {
              setOpenPricePopupSave(true);
            } else {
              setOpenPricePopupSave(false);
              checkSavePrerequisits();
            }
            setConfiguredPrice(val?.configuredPercentage);
          }, () => {
            priceValidationFail();
          });
        } else {
          priceValidationFail();
        }
      }, () => {
        priceValidationFail();
      });
    } else {
      setOpenPricePopupSave(false);
      checkSavePrerequisits();
    }
  };

  const handleNoItemCloseModal = (type?: number) => {
    switch (type) {
      case 1:
        buildComparisonPayload();
        break;
      case 2:
        setOpenConnectItemPanel(true);
        break;
      default:
        break;
    }
    setOpenConnectItemModal(false);
  };
  const onStartDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setStartDate(date);
      setUnknownStartDate(false);
    } else {
      setStartDate(null);
    }
  };

  const onEndDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setEndDate(date);
      setUnknownEndDate(false);
    } else {
      setEndDate(null);
    }
  };

  const renderRetailUnitSection = () => (
    showRetailUnit && (
      <div className={styles.sectionWrapper}>
        <SectionTitle sectionTitle={retailUnitTitle} />
        <SectionDescription sectionDescription={retailUnitDescription} />
        <SectionDescription sectionDescription={retailUnitCurrencyDescription} />
        <Select<number, { value: string; children: any }>
          className={`ruSelect ${isFindCompare && styles.disabled}`}
          showSearch
          getPopupContainer={(trigger) => trigger.parentNode}
          suffixIcon={!isFindCompare ? <SSRIcon paths={IcDownArrow} /> : null}
          placeholder={retailUnitPlaceholder}
          optionFilterProp="children"
          value={isFindCompare ? retailUnits.findIndex((option: any) => option.ruCode === selectedRUCode)
            : selectedRU?.ruVal}
          onChange={onRetailUnitChange}
          virtual={false}
          filterOption={(input, option) => option?.children.props.children[1].props.children
            .toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={isFindCompare}
          data-testid="retail-unit-select"
        >
          {retailUnits.map((retailUnit, index) => (
            <Option value={index} key={retailUnit.key}>
              <div className={styles.ruItemWrapper}>
                <img className="table-image-left" src={flags[retailUnit.ruCode]} alt={retailUnit.ruCode} />
                <span id="ruName" data-testid={`retail-unit-name-${retailUnit.ruName}`}>{retailUnit.ruName}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>
    )
  );

  const fetchUnits = (passedItem?: Item | any | null) => {
    setFetchFoodFlag(true);
    dispatch(setLoader(true));
    const payload = {
      ItemType: passedItem?.itemType || connectedItem?.itemType,
      ItemNo: passedItem?.itemNo || connectedItem?.itemNo,
      MeasureSystem: measuringSystem,
    };
    post('get-fooditem-details', payload, (data: any) => {
      setFetchFoodFlag(false);
      const unitList = JSON.parse(localStorage.getItem('unitList') || '');
      const unitData = data?.validUnits;
      const priceData = data?.priceUnit?.content[0];
      let selUnit = '';
      if (!isNullEmptyOrUndefined(data) && !isNullEmptyOrUndefined(unitData)
      && !isNullEmptyOrUndefined(priceData) && !isNullEmptyOrUndefined(unitList)) {
        setIkeaPriceObject({
          PriceUnitTypeNo: priceData?.priceUnitTypeNo,
          PriceUnitType: priceData?.priceUnitTypeName,
          priceUnitText: priceData?.priceUnitText,
          priceUnitValue: priceData?.priceUnitValue,
          priceUnitTextMetric: priceData?.priceUnitTextMetric,
          priceUnitTextImperial: priceData?.priceUnitTextImperial,
          priceUnitFactorMetric: priceData?.priceUnitFactorMetric,
          priceUnitFactorImperial: priceData?.priceUnitFactorImperial,
        });
        const updatedUnits: any[] = [];
        unitData.forEach((apiUnit: any) => {
          updatedUnits.push(...unitList.filter((localUnit: any) => apiUnit.toLowerCase() === localUnit.code.toLowerCase()));
        });
        // append pieces
        updatedUnits.unshift(pieces);
        setUnitListDisplay(updatedUnits);
        // select the corresponding unit
        if (isEditComparison() === false) {
          if (measuringSystem.includes('IMP')) {
            selUnit = updatedUnits.find((item) => item.code === priceData.priceUnitTextImperial)?.code || pieces.code;
          } else {
            selUnit = updatedUnits.find((item) => item.code === priceData.priceUnitTextMetric)?.code || pieces.code;
          }
          onUnitSelect(selUnit);
        }
      } else {
        selectNonFoodHfb();
      }
      // scroll to field
      if (isInstantComparison() && !isNullEmptyOrUndefined(selUnit)) {
        piecesRef?.current?.scrollIntoView({ behavior: 'smooth' });
        if (selUnit === pieces.code) {
          setPieceFocus(true);
        } else {
          setQtyFocus(true);
        }
      }
      dispatch(setLoader(false));
    }, () => {
      setFetchFoodFlag(false);
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: 'Failed to fetch the units' }));
    });
  };

  const fetchRuCode = () => {
    if (isSu()) {
      return localStorage.getItem('RuCodeForSu');
    }
    return selectedRU?.ruCode;
  };

  const fetchWholeUnitsList = () => {
    offlineData.getItem('enumData').then((data: any) => {
      // get the food hfb list
      const foodhfbs = data.find((item: any) => item.key === 'FOOD_HFB_LIST')?.value?.split('|');
      setFoodHFBs(foodhfbs);
      // get the measurement system
      const measSys = data.filter((item: any) => item.groupName === 'RUMeasUnits');
      const filteredMeasSys = measSys.filter((item:any) => item.key === fetchRuCode());
      const measureSystems: Array<string> = [];
      filteredMeasSys.forEach((sys: any) => measureSystems.push(sys.value));
      setMeasuringSystem(measureSystems);
      // get the units
      const units:Array<Units> = [];
      const filteredList = data.filter((item: any) => measureSystems.includes(item.groupName));
      filteredList.length !== 0 && filteredList.forEach((filteredItem: any) => {
        units.push({
          system: filteredItem.groupName,
          code: filteredItem.key,
          unit: filteredItem.value,
        });
      });
      units.unshift(pieces);
      setUnitListDisplay(units);
      if (isEditComparison()) {
        const comparisonUnit = location.state?.comparisonDetail?.comparisonDetailViewInfoDto?.compPriceUnitText;
        if (!isNullEmptyOrUndefined(comparisonUnit)) {
          onUnitSelect(comparisonUnit);
        } else {
          selectNonFoodHfb();
        }
      } else {
        onUnitSelect(pieces.code);
      }
      localStorage.setItem('unitList', JSON.stringify([...units]));
    });
  };

  // <<--start-- Food hfb initial load for View/Edit comparison
  useEffect(() => {
    if (isEditComparison() && !isNullEmptyOrUndefined(selectedRU)) {
      fetchWholeUnitsList();
    }
  }, [selectedRU]);

  const onEditItemDisconnectOrConnect = (item: Item | any | null) => {
    if (!isNullEmptyOrUndefined(item)) {
      fetchUnits(item);
    } else {
      setIsFoodHfb(false);
      const unitList = JSON.parse(localStorage.getItem('unitList') || '');
      setUnitListDisplay(unitList);
      setDisablePieces(false);
    }
  };
  // View/Edit comparison --end-->>

  // <<--start-- Food hfb initial load for Project based comparison
  useEffect(() => {
    if (isProjectBasedComparison() && !isNullEmptyOrUndefined(selectedRU)) {
      fetchWholeUnitsList();
    }
  }, [selectedRU]);

  useEffect(() => {
    if (isProjectBasedComparison() && !isNullEmptyOrUndefined(selectedRU)) {
      if (foodHFBs.length > 0) {
        const itemHFB = connectedItem?.paNo?.slice(0, 2);
        const foodhfb = foodHFBs?.includes(itemHFB);
        setIsFoodHfb(foodhfb === true);
        if (foodhfb === false) {
          selectNonFoodHfb();
        }
      }
    }
  }, [foodHFBs]);

  useEffect(() => {
    if (isProjectBasedComparison() && !isNullEmptyOrUndefined(selectedRU)) {
      if (foodHFBs?.length > 0 && measuringSystem?.length > 0 && isFoodHfb === true && !isNullEmptyOrUndefined(connectedItem)) {
        fetchUnits();
        setDisablePieces(false);
      }
    }
  }, [connectedItem, foodHFBs, measuringSystem, isFoodHfb]);
  // Project based comparison --end-->>

  // <<--start-- Food hfb initial load for Instant comparison
  useEffect(() => {
    if (!isNullEmptyOrUndefined(selectedRU) && isInstantComparison()) {
      if (foodHFBs?.length > 0 && !isNullEmptyOrUndefined(connectedItem)) {
        if (isFoodHfb === true) {
          fetchUnits();
          setDisablePieces(false);
        } else {
          selectNonFoodHfb();
        }
      } else {
        fetchWholeUnitsList();
        setDisablePieces(false);
      }
    }
  }, [selectedRU, isFoodHfb]);

  useEffect(() => {
    if (isInstantComparison()) {
      if (!isNullEmptyOrUndefined(connectedItem) && foodHFBs?.length > 0) {
        const itemHFB = connectedItem?.paNo?.slice(0, 2);
        const foodhfb = foodHFBs?.includes(itemHFB);
        setIsFoodHfb(foodhfb === true);
        if (foodhfb === false) {
          selectNonFoodHfb();
        }
      } else if (isNullEmptyOrUndefined(connectedItem) && foodHFBs?.length > 0) {
        setIsFoodHfb(false);
        const unitList = JSON.parse(localStorage.getItem('unitList') || '');
        setUnitListDisplay(unitList);
        setDisablePieces(false);
      } else {
        setIsFoodHfb(false);
        (isSu() || !isNullEmptyOrUndefined(selectedRU)) && fetchWholeUnitsList();
        setDisablePieces(false);
      }
    }
  }, [connectedItem, foodHFBs]);
  // Instant comparison --end-->>

  const onQuantityChange = (val: any) => {
    if (isPieces() && !val.toString().includes('.')) {
      setQuantityError(false);
    }
    setItemQuantity(val);
  };

  const disableQtyDropdown = () => {
    if (disablePieces) {
      return true;
    } if (isEditComparison() && disableAllEditFields) {
      return true;
    } if (disableAllFields) {
      return true;
    }
    return false;
  };

  const showIKEANoOfPieces = () => {
    if (isFindCompare) {
      return (!isNullEmptyOrUndefined(connectedItem) && !disableAllEditFields);
    }
    if (!isFindCompare && !isNullEmptyOrUndefined(connectedItem)) {
      return true;
    }
    return false;
  };

  const getMultipack = () => {
    const ikeaMultiPackDet = !isNullEmptyUndefinedOrZero(connectedItem?.ikeaMultiPack) ? connectedItem?.ikeaMultiPack : null;
    return ikeaMultiPackDet || null;
  };

  const showIKEANoOfPiecesValueWithUnits = () => {
    if (isPieces()) {
      const ikeaMultiPack = !isNullEmptyUndefinedOrZero(connectedItem?.ikeaMultiPack) ? connectedItem?.ikeaMultiPack : null;
      return ikeaMultiPack ? `${ikeaMultiPack} piece(s)` : '';
    }
    const getIKEAPriceUnitFullText = unitListDisplay.filter((val:any) => val.code === getIkeaPriceUnitText());
    return `${getIkeaPriceUnitValue()}  ${getIKEAPriceUnitFullText[0]?.unit}`;
  };

  const quantityValidation = (itmQty:any) => (!isNullEmptyUndefinedOrZero(itmQty) && checkZeroRegex.test(itmQty?.toString() || '') === false);

  const renderNumberOfPieces = () => (
    <div className={styles.sectionWrapper} ref={piecesRef}>
      <SectionTitle sectionTitle={numberofPiecesTitle} />
      <SectionDescription sectionDescription={numberofPiecesDescription} />
      <div className={styles.icPiecesWrapper}>
        {isPieces() ? (
          <FormField
            className={styles.ifNumberOfPiecesWrapper}
            shouldValidate={quantityError}
            validation={{
              id: 'item-quantity-error',
              msg: 'Decimals not allowed for pieces unit',
              type: 'error',
            }}
          >
            <NumberInput
              className={styles.ifNumberOfPieces}
              id="numberOfPieces"
              label={labelQuantity}
              value={itemQuantity}
              charLimit={13}
              onChange={onQuantityChange}
              disabled={isFindCompare ? disableAllEditFields : disableAllFields}
              pieceFocus={pieceFocus === true}
              data-testid="pieces-input"
            />
          </FormField>
        ) : (
          <QuantityInput
            className={styles.ifNumberOfPiecesFood}
            id="piecesQty"
            label={labelQuantity}
            value={itemQuantity}
            charLimit={13}
            onChange={onQuantityChange}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields}
            pieceFocus={qtyFocus === true}
            data-testid="quantity-input"
          />
        )}
        <SelectSkapa
          id="unitSelection"
          className={styles.icUnitSelect}
          label={labelUnits}
          value={selectedUnit}
          onChange={(e) => onUnitSelect(e.target.value)}
          hintText=""
          disabled={disableQtyDropdown()}
          data-testid="unit-selection"
        >
          {unitListDisplay.map((unit, index) => (
            <OptionSkapa key={`${unit}${index.toString()}`} value={unit.code} name={unit.unit} />
          ))}
        </SelectSkapa>
      </div>
      {(showIKEANoOfPieces() && ((isPieces() === false && getIkeaPriceUnitValue() && fetchFoodFlag === false) || (isPieces() === true && getMultipack() && fetchFoodFlag === false))) ? (
        <div className={`${styles.icIKEAPieces}`}>
          <Text className={styles.icIKEAPiecesText}>
            {ikeaPiecesText}
            {' '}
            {showIKEANoOfPiecesValueWithUnits()}
          </Text>
        </div>
      ) : null}
      {isPieces() ? (
        <Button
          className={styles.btOnePiece}
          text={onePieceText}
          type="emphasised"
          onClick={onOnePieceClick}
          disabled={isFindCompare ? (disableAllEditFields || quantityValidation(itemQuantity) === true)
            : (disableAllFields || quantityValidation(itemQuantity) === true)}
          small
        />
      ) : null}
    </div>
  );

  const setCompetitor = (value: string | number, name: string | number, type: string) => {
    if (type === 'id') {
      setSelectedCompetitorId(value);
      setSelectedCompetitor(name);
    } else {
      setSelectedCompetitor(value);
      setSelectedCompetitorId('');
    }
  };

  const setRadioVal = (e: any, type: string) => {
    switch (type) {
      case 'quality':
        setItemQuality(e.target.value);
        break;
      case 'functionality':
        setItemFunctionality(e.target.value);
        break;
      case 'assemble':
        setItemAssembled(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    const fList = newFileList.filter((val:any) => uploadFileTypes.includes(val.type) || val.url);
    (fList.length > 3 ? setFileList(fList.slice(0, 3)) : setFileList(fList));
  };

  useEffect(() => {
    if (previewImageList?.length > 0) {
      setImgPreviewOpen(true);
    }
  }, [previewImageList]);

  const handleImagePreview = async (previewFile: UploadFile) => {
    const previewFileList: any[] = [];
    await fileList.reduce(async (promise, file) => {
      await promise;
      let imgPreview: any = null;
      if (!file.url && !file.preview) {
        imgPreview = await getBase64(file.originFileObj as RcFile);
      }
      previewFileList.push(file.url || (file.preview as string) || imgPreview);
    }, Promise.resolve());
    let imgPreviewFile: any = null;
    if (!previewFile.url && !previewFile.preview) {
      imgPreviewFile = await getBase64(previewFile.originFileObj as RcFile);
    }
    setPreviewImage(previewFile.url || (previewFile.preview as string) || imgPreviewFile);
    setPreviewImageList(previewFileList);
  };

  const uploadProps: UploadProps = {
    name: 'IMAGE',
    listType: 'picture',
    showUploadList: {
      removeIcon: <SSRIcon className={styles.icDelete} paths={IcDelete} />,
      showPreviewIcon: true,
    },
    maxCount: 3,
    className: `upload-list-inline ${fileList.length >= 3 ? 'disableUpload' : ''}`,
    multiple: true,
    accept: 'image/*',
    onPreview: handleImagePreview,
    onRemove: (file) => {
      const isOriginFile = isNullEmptyOrUndefined(file?.originFileObj);
      if (isOriginFile === true) {
        setDeletedImages([...deletedImages, file]);
      } else {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      }
    },
    beforeUpload: (file) => {
      const isFileSupported = uploadFileTypes.includes(file.type);
      if (!isFileSupported) {
        dispatch(setToast({ openToast: true, toastLabel: fileUploadErr }));
      } else {
        setFileList((fileLists) => [...fileLists, file]);
      }
      return false;
    },
    fileList,
    onChange: handleChange,
  };

  const setAddNewComp = () => {
    setCompetitorsList([...competitorList, { competitorName: searchCompValue, competitorId: searchCompValue }]);
    setAddNewCompFlag(!addNewCompFlag);
    setCompetitor(searchCompValue, searchCompValue, 'id');
  };

  useEffect(() => {
    const emptyElement = document.getElementsByClassName('ant-select-item-empty');
    if (emptyElement) {
      emptyElement.length === 0 ? setNoCompData(false) : setNoCompData(true);
    }
  });

  const renderCompetitorSection = () => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={competitorTitle} />
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText="No data found"
          icon={ListIcon}
          smallType
        />
      )}
      >
        <Select
          showSearch
          getPopupContainer={(trigger) => trigger.parentNode}
          className={`ruSelect ${isFindCompare ? disableAllEditFields : disableAllFields && styles.disabled}`}
          options={competitorList || []}
          placeholder={competitorPlaceholder}
          fieldNames={{
            label: 'competitorName',
            value: 'competitorName',
          }}
          autoClearSearchValue={false}
          defaultOpen={false}
          popupClassName="icCompetitorPopup"
          defaultActiveFirstOption={false}
          value={selectedCompetitor as any}
          defaultValue={selectedCompetitor as any}
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          onSelect={(val: any, option: any) => setCompetitor(option.competitorId, option.competitorName, 'id')}
          onFocus={() => setNoCompData(false)}
          onSearch={(val: any) => {
            setSearchCompValue(val);
            val && setAddNewCompFlag(false);
          }}
          filterOption={
          (inputValue, option) => option?.competitorName?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
          dropdownRender={(menu) => (
            <>
              {menu}
              {(noCompData === true && addNewCompFlag === false && !isNullEmptyOrUndefined(searchCompValue)) ? (
                <div className="new-comp-check">
                  <Checkbox
                    id="addNewComp"
                    name="addNewComp"
                    onChange={setAddNewComp}
                    data-testid="add-new-competitor-checkbox"
                  >
                    {`Add “${searchCompValue}” as a new competitor`}
                  </Checkbox>
                </div>
              ) : null}
            </>
          )}
          data-testid="competitor-select"
        />
      </ConfigProvider>
    </div>
  );

  const renderCompetitorPrice = () => (
    <div className={styles.sectionWrapper} ref={priceRef}>
      <SectionTitle sectionTitle={competitorPriceTitle} />
      <PriceInput
        className={styles.ifNumberOfPieces}
        id="competitorPrice"
        label={labelCurrentPrice}
        value={competitorPrice}
        currency={currency || null}
        charLimit={11}
        disabled={isFindCompare ? disableAllEditFields : disableAllFields}
        onChange={setCompetitorPrice}
        priceFocus={priceFocus}
      />
      <Switch
        className={styles.swTimeRestricted}
        checked={showTimeRestricted}
        disabled={isFindCompare ? disableAllEditFields : disableAllFields}
        id="swTimeRestricted"
        label={switchText}
        value={switchValue}
        onChange={() => {
          setShowTimeRestricted(!showTimeRestricted);
          setRegularPrice('');
          setUnknownRegularPrice(false);
          setStartDate(null);
          setUnknownStartDate(false);
          setEndDate(null);
          setUnknownEndDate(false);
        }}
      />
      {showTimeRestricted && (
        <div className={styles.timeRestrictedWrapper}>
          <SectionTitle sectionTitle={regularPriceTitle} />
          <PriceInput
            className={styles.ifNumberOfPieces}
            id="regularPrice"
            label={labelPrice}
            value={regularPrice}
            currency={currency || null}
            charLimit={11}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields}
            onChange={(val) => {
              setRegularPrice(val);
              setUnknownRegularPrice(false);
            }}
            onBlur={() => null}
          />
          <RadioButton
            className={styles.radioButton}
            id="rb_regularPrice"
            label={radioButtonText}
            name="rb_regularPrice"
            value={radioButtonValue}
            checked={unknownRegularPrice === true}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields || !isNullEmptyOrUndefined(regularPrice)}
            onClick={() => {
              setRegularPrice('');
              setUnknownRegularPrice(!unknownRegularPrice);
            }}
          />
          <SectionTitle
            customClass={styles.titleCustomStyle}
            sectionTitle={restrictedOfferTimeStart}
          />
          <Text
            className={styles.dateLabel}
            headingSize="s"
            tagName="p"
          >
            {labelStartDate}
          </Text>
          <DatePicker
            className={styles.dateInput}
            onChange={onStartDateChange}
            suffixIcon={<SSRIcon paths={IcDownArrow} className={styles.icon} />}
            placeholder={compDate}
            value={startDate}
            allowClear
            clearIcon={<SSRIcon paths={IcCross} className={styles.icon} />}
            disabledDate={(d) => !d || d.isAfter(dayjs(endDate))}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields}
            popupClassName="icDatePickerDropdown"
          />
          <RadioButton
            className={styles.radioButton}
            id="rb_startDate"
            label={radioButtonText}
            name="rb_startDate"
            value={radioButtonValue}
            checked={unknownStartDate === true}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields || !isNullEmptyOrUndefined(startDate)}
            onClick={() => {
              setStartDate(null);
              setUnknownStartDate(!unknownStartDate);
            }}
          />
          <SectionTitle
            customClass={styles.titleCustomStyle}
            sectionTitle={restrictedOfferTimeEnd}
          />
          <Text
            className={styles.dateLabel}
            headingSize="s"
            tagName="p"
          >
            {labelEndDate}
          </Text>
          <DatePicker
            className={styles.dateInput}
            onChange={onEndDateChange}
            suffixIcon={<SSRIcon paths={IcDownArrow} className={styles.icon} />}
            placeholder={compDate}
            value={endDate}
            allowClear
            clearIcon={<SSRIcon paths={IcCross} className={styles.icon} />}
            disabledDate={(d) => {
              dayjs.extend(isSameOrBefore);
              const date = dayjs(d);
              return !date
                  || (startDate !== null && !date.isAfter(dayjs(startDate)))
                  || date.isSameOrBefore(dayjs().subtract(1, 'days'));
            }}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields}
            popupClassName="icDatePickerDropdown"
          />
          <RadioButton
            className={styles.radioButton}
            id="rb_endDate"
            label={radioButtonText}
            name="rb_endDate"
            value={radioButtonValue}
            checked={unknownEndDate === true}
            disabled={isFindCompare ? disableAllEditFields : disableAllFields || !isNullEmptyOrUndefined(endDate)}
            onClick={() => {
              setEndDate(null);
              setUnknownEndDate(!unknownEndDate);
            }}
          />
        </div>
      )}
    </div>
  );

  const renderItemQualityFunctionality = (title: string, qualityFunctionality: string | undefined) => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={`Competitor item ${title}?`} />
      <SectionDescription sectionDescription={qualityDescription} />
      <div className={`flexRow ${styles.radioButton}`}>
        <RadioButton
          className="radioGap"
          id={`item${title}Better`}
          name={`item${title}radio`}
          key={`item${title}Better`}
          value="better"
          label="Better"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          checked={qualityFunctionality === 'better'}
          onChange={(val) => setRadioVal(val, title)}
        />
        <RadioButton
          id={`item${title}Equal`}
          name={`item${title}radio`}
          key={`item${title}Equal`}
          className="radioGap"
          value="equal"
          label="Equal"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          checked={qualityFunctionality === 'equal'}
          onChange={(val) => setRadioVal(val, title)}
        />
        <RadioButton
          id={`item${title}Worse`}
          name={`item${title}radio`}
          key={`item${title}Worse`}
          value="worse"
          label="Worse"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          checked={qualityFunctionality === 'worse'}
          onChange={(val) => setRadioVal(val, title)}
        />
      </div>
    </div>
  );

  const renderItemAssembled = (title: string) => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={itemAsembleTitle} />
      <div className={`flexRow ${styles.radioButton}`}>
        <RadioButton
          className="radioGap"
          id={`item${title}Yes`}
          name={`item${title}radio`}
          key={`item${title}Yes`}
          value="true"
          label="Yes"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          checked={itemAssembled === 'true'}
          onChange={(val) => setRadioVal(val, title)}
        />
        <RadioButton
          id={`item${title}No`}
          name={`item${title}radio`}
          key={`item${title}No`}
          value="false"
          label="No"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          checked={itemAssembled === 'false'}
          onChange={(val) => setRadioVal(val, title)}
        />
      </div>
      <div />
    </div>
  );

  const onOptionalTextAreaChange = (value: string, type: ComparisonTextAreaProps) => {
    const textVal = value.length <= type.charLimit ? value : value.slice(0, type.charLimit);
    if (!isNullEmptyOrUndefined(textVal)) {
      if (checkInvalidCharRegex.test(textVal) === false && checkValidCharRegex.test(textVal) === true) {
        type.textErr = false;
      } else {
        type.textErr = true;
      }
    } else type.textErr = false;
    switch (type.id) {
      case 0:
        setMaterial(textVal);
        break;
      case 1:
        setSustainability(textVal);
        break;
      case 2:
        setComment(textVal);
        break;
      default:
        break;
    }
  };

  const OptionalTextAreaValue = (type: ComparisonTextAreaProps) => {
    switch (type.id) {
      case 0:
        return material;
      case 1:
        return sustainability;
      case 2:
        return comment;
      default:
        return '';
    }
  };

  const renderOptionalTextArea = (type: ComparisonTextAreaProps) => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={type.title} />
      <FormField
        shouldValidate={type.textErr}
        validation={{
          id: 'error-msg-textArea',
          msg: textAreaErr,
          type: 'error',
        }}
        className={styles.textArea}
        characterLimit={type.charLimit}
      >
        <TextArea
          id={type.title}
          label={type.label}
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          value={OptionalTextAreaValue(type)}
          onChange={(e) => onOptionalTextAreaChange(e.target.value, type)}
        />
      </FormField>
    </div>
  );

  const changeCompetitorItemName = (e: any) => {
    const val = e.target.value;
    val.length <= 100 && setCompetitorItemName(e.target.value);
  };

  const renderItemName = () => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={competitorName} />
      <InputField
        className={styles.inputField}
        id="itemName"
        label={competitorValue}
        type="text"
        autoComplete="off"
        value={competitorItemName}
        disabled={isFindCompare ? disableAllEditFields : disableAllFields}
        onChange={changeCompetitorItemName}
      />
    </div>
  );

  const changeCompetitorItemUrl = (e: any) => {
    setCompetitorItemUrl(e.target.value);
  };

  const validateCompetitorItemUrl = () => {
    if (competitorItemUrl && competitorItemUrl.length > 0 && !isValidUrl(competitorItemUrl)) {
      setUrlError(true);
    } else {
      setUrlError(false);
    }
    setUrlThreatErr(false);
  };

  const renderItemUrl = () => (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={competitorUrlTitle} />
      <FormField
        shouldValidate={urlError}
        validation={{
          id: 'error-msg-id',
          msg: urlThreatErr ? threatUrlMsg : invalidUrlMsg,
          type: 'error',
        }}
      >
        <InputField
          className={styles.inputField}
          id="itemUrl"
          label={competitorUrlValue}
          type="text"
          value={competitorItemUrl}
          autoComplete="off"
          disabled={isFindCompare ? disableAllEditFields : disableAllFields}
          onChange={changeCompetitorItemUrl}
          onBlur={validateCompetitorItemUrl}
        />
      </FormField>
    </div>
  );

  const renderPhotoSection = () => (
    <div className={`${styles.sectionWrapper} icDesktopPhotoParent`}>
      <SectionTitle sectionTitle={photoTitle} optional />
      <SectionDescription sectionDescription={photoDescription} />
      <div className={styles.photoUploadContainer}>
        <Text className={styles.photoTitle}>{photoTextOne}</Text>
        <Text className={styles.photoSubTitle}>{photoTextTwo}</Text>
        <Dragger style={{ marginTop: '40px' }} {...uploadProps} disabled={isFindCompare ? disableAllEditFields : disableAllFields}>
          <p className={`ant-upload-drag-icon ${(isFindCompare ? disableAllEditFields : disableAllFields) ? 'disableUpload' : ''}`}>
            <SSRIcon className="emptyDataIcon icPhoto" paths={IcImage} data-testid="photo-upload-text2" />
          </p>
          <p className="ant-upload-text" data-testid="photo-upload-svg">{PhotoTextThree}</p>
        </Dragger>
      </div>
    </div>
  );

  const priceValidation = (val:any) => {
    if (isNullEmptyUndefinedOrZero(val) || val === '.' || checkZeroRegex.test(val)) { return false; }
    return true;
  };

  const validTimeRestrictedFields = () => {
    if (showTimeRestricted) {
      return (priceValidation(regularPrice) || unknownRegularPrice)
        && (!isNullEmptyOrUndefined(startDate) || unknownStartDate)
        && (!isNullEmptyOrUndefined(endDate) || unknownEndDate);
    }
    return true;
  };

  const validCompetitor = () => {
    const competitor: any = selectedCompetitor;
    if (!competitor || competitor?.length === 0
      || (competitor?.trim().length === 0 && competitor?.length > 0)) {
      return false;
    }
    return true;
  };

  const validUrl = () => {
    if (competitorItemUrl) {
      return !isNullEmptyOrUndefined(competitorItemUrl) && !urlError;
    }
    return true;
  };

  const disableSaveButton = () => {
    if (!isNullEmptyOrUndefined(selectedRU)
      && validCompetitor()
      && quantityValidation(itemQuantity)
      && priceValidation(competitorPrice)
      && validTimeRestrictedFields()
      && !isNullEmptyOrUndefined(itemQuality)
      && !isNullEmptyOrUndefined(itemFunctionality)
      && !isNullEmptyOrUndefined(itemAssembled)
      && ComparisonTextArea.comment.textErr === false
      && ComparisonTextArea.sustainability.textErr === false
      && ComparisonTextArea.material.textErr === false
      && validUrl()) {
      return false;
    }
    return true;
  };

  const comparisonDelete = () => {
    const payload = {
      comparisonId: comparsionId,
    };
    post('delete-comparison', payload, (data: any) => {
      setopenDeleteComparison(false);
      navigateToFC();
      dispatch(setToast({
        openToast: true,
        toastLabel: 'Comparison has been deleted',
      }));
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: 'Failed to delete the comparison' }));
    });
  };

  const openDeleteModal = () => {
    setopenDeleteComparison(true);
  };

  const handleDeleteModal = (type?: number) => {
    if (type === 2) {
      comparisonDelete();
    } else {
      setopenDeleteComparison(false);
    }
  };

  const comparisonEdit = () => {
    if (isFoodHfb && !isNullEmptyOrUndefined(connectedItem?.itemNo) && measuringSystem.length > 0) {
      fetchUnits();
    } else {
      selectNonFoodHfb();
    }
    setDisableAllEditFields(false);
  };

  const renderProjectEditAndDeleteBtn = () => (
    <div className={styles.icProjectDetailsWrapper}>
      {(projectDetails?.projectName && showProjData === true) ? (
        <div className={styles.icProjectTextWrapper}>
          <Text className={styles.icProjectTextTitle}>{projectName}</Text>
          <Text className={styles.icProjectTextDesc}>{projectDetails?.projectName}</Text>
        </div>
      ) : null}
      <div className={styles.icProjectBtnWrapper}>
        {isFCEdit === true ? (
          <Button
            className={styles.button}
            style={{ width: '164px' }}
            text="Edit Comparison"
            type="secondary"
            onClick={comparisonEdit}
            small
          />
        ) : null}
        {isFCDelete === true ? (
          <Button
            className={styles.button}
            iconOnly
            small
            type="tertiary"
            onClick={openDeleteModal}
          >
            <SSRIcon paths={IcDelete} />
          </Button>
        ) : null}
      </div>
    </div>
  );

  const renderEditAndDeleteBtn = () => (
    <div className={styles.icProjectBtnWrapper}>
      {isFCEdit === true ? (
        <Button
          className={styles.button}
          style={{ width: '164px' }}
          text="Edit Comparison"
          type="secondary"
          onClick={comparisonEdit}
          small
        />
      ) : null}
      {isFCDelete === true ? (
        <Button
          className={styles.button}
          iconOnly
          small
          type="tertiary"
          onClick={openDeleteModal}
        >
          <SSRIcon paths={IcDelete} />
        </Button>
      ) : null}
    </div>
  );

  const renderComparisonProjectDetails = () => {
    const isProject = !isNullEmptyOrUndefined(projectId);
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isFindCompare === true ? (
          <div className={isProject ? styles.icHeaderProjectContainer
            : styles.icHeaderContainer}
          >
            <div className={isProject ? styles.icProjectDetailsTextContentWrapper
              : styles.icProjectTextContentWrapper}
            >
              {(isProject && projectDetails?.projectStartDate && showProjData === true) ? (
                <div className={styles.icProjectTextWrapper}>
                  <Text className={styles.icProjectTextTitle}>{projectStartDate}</Text>
                  <Text className={styles.icProjectTextDesc}>
                    {projectDetails?.projectStartDate}
                  </Text>
                </div>
              ) : null}
              <div className={styles.icProjectTextWrapper}>
                <Text className={styles.icProjectTextTitle}>{projectComparedOn}</Text>
                <Text className={styles.icProjectTextDesc}>{projectDetails?.comparedOnText}</Text>
              </div>
              {!isProject && projectDetails?.updatedOnText ? (
                <div className={styles.icProjectTextWrapper}>
                  <Text className={styles.icProjectTextTitle}>{projectUpdatedOn}</Text>
                  <Text className={styles.icProjectTextDesc}>{projectDetails?.updatedOnText}</Text>
                </div>
              ) : null}
              {(isProject && projectDetails?.projectEndDate && showProjData === true) ? (
                <div className={styles.icProjectTextWrapper}>
                  <Text className={styles.icProjectTextTitle}>{projectEndDate}</Text>
                  <Text className={styles.icProjectTextDesc}>{projectDetails?.projectEndDate}</Text>
                </div>
              ) : null}
            </div>
            {isProject ? renderProjectEditAndDeleteBtn() : renderEditAndDeleteBtn()}
          </div>
        ) : null}
      </>
    );
  };

  const renderItemDetailsRAddItem = () => {
    const isItem = !isNullEmptyOrUndefined(connectedItem?.itemNo);
    return (
      <>
        {isItem && (!isFindCompare || !isNullEmptyOrUndefined(projectId)) ? (
          <Button
            className="btnAction"
            text={seeConnectIkeaItem}
            type="secondary"
            onClick={toggleProjectItemPanel}
            small
          />
        ) : (
          <Button
            className={`btnAction ${!isItem && disableAllEditFields ? 'btDisabled' : ''}`}
            text={isItem && disableAllEditFields ? seeConnectIkeaItem : connectIKEA}
            type="secondary"
            onClick={isItem && disableAllEditFields ? toggleProjectItemPanel : toggleConnectItemPanel}
            disabled={!isItem && disableAllEditFields}
            small
          />
        )}
      </>
    );
  };

  return (
    <div>
      <header className="headerContainer">
        {isNullEmptyOrUndefined(projectId) ? (
          <Text className={styles.icPageTitle}>Instant comparison</Text>
        ) : (
          <Text className={styles.icPageTitle}>Project comparison</Text>
        )}
        {!isNullEmptyOrUndefined(connectedItem && connectedItem?.itemNo) ? (
          <div style={{ display: 'flex' }}>
            <Text className={styles.regularFont}>
              {isFindCompare && !isNullEmptyOrUndefined(connectedItem && connectedItem?.itemNo)
                && disableAllEditFields ? comparisonAdded : addingComparison}
            </Text>
            <Text className={styles.regularFont}>&nbsp;</Text>
            <Text className={styles.boldFont}>{connectedItem.itemName}</Text>
          </div>
        ) : <Text className={styles.boldFont}>{noItemConnected}</Text>}
        {isInstantCompare === true ? (
          <Button
            className={`btnAction ${disableAllFields ? 'btDisabled' : ''}`}
            text={!isNullEmptyOrUndefined(connectedItem?.itemNo) ? seeConnectIkeaItem : connectIKEA}
            type="secondary"
            onClick={toggleConnectItemPanel}
            disabled={disableAllFields}
            small
          />
        ) : (
          <>
            {renderItemDetailsRAddItem()}
          </>
        )}
        <div className="iconsWrapper">
          <AppBarIcons closeFn={handleCloseModal} />
        </div>
      </header>
      <div className={styles.contentWrapper}>
        {renderComparisonProjectDetails()}
        {renderRetailUnitSection()}
        {renderCompetitorSection()}
        {renderNumberOfPieces()}
        {renderCompetitorPrice()}
        {renderItemQualityFunctionality('quality', itemQuality)}
        {renderItemQualityFunctionality('functionality', itemFunctionality)}
        {renderItemAssembled('assemble')}
        {renderPhotoSection()}
        {renderOptionalTextArea(ComparisonTextArea.material)}
        {renderOptionalTextArea(ComparisonTextArea.sustainability)}
        {renderOptionalTextArea(ComparisonTextArea.comment)}
        {renderItemName()}
        {renderItemUrl()}
        <Button
          className={styles.btOnePiece}
          text={isFindCompare ? updateComparisonTitle : saveComparisonTitle}
          type="emphasised"
          onClick={checkCompPriceValid}
          disabled={isFindCompare ? (disableAllEditFields || disableSaveButton()) : disableSaveButton()}
          small
        />
      </div>
      <ConnectItemPanel
        isVisible={openConnectItemPanel}
        connectedItem={connectedItem}
        selectedRU={selectedRU}
        onClose={toggleConnectItemPanel}
        setConnectedItem={(item) => {
          setConnectedItem(item);
          isEditComparison() && onEditItemDisconnectOrConnect(item);
        }}
      />
      <ProjectItemPanel
        isVisible={openProjectItemPanel}
        connectedItem={connectedItem}
        onClose={toggleProjectItemPanel}
      />
      <CloseModal
        modelOpen={openCloseModal}
        closeModal={handleCloseModalButtons}
        saveValid
        closeText={useTranslate('inst.comp.closeModal_closetext')}
        saveText={useTranslate('inst.comp.closeModal_savetext')}
        title={useTranslate('inst.comp.noitemModal_title')}
        subText={useTranslate('inst.comp.closeModal_subtext')}
      />
      <CloseModal
        modelOpen={openConnectItemModal}
        closeModal={handleNoItemCloseModal}
        saveValid
        closeText={useTranslate('inst.comp.noitemModal_closetext')}
        saveText={useTranslate('inst.comp.noitemModal_savetext')}
        title={useTranslate('inst.comp.noitemModal_title')}
        subText={useTranslate('inst.comp.noitemModal_subtext')}
      />
      <CloseModal
        modelOpen={openPricePopupSave}
        closeModal={handlePriceModalButtons}
        saveValid
        closeText={useTranslate('inst.comp.priceModal_closetext')}
        saveText={useTranslate('inst.comp.priceModal_savetext')}
        title={useTranslate('inst.comp.noitemModal_title')}
        subText={`${useTranslate('inst.comp.priceModal_subtext1')}${configuredPrice}${useTranslate('inst.comp.priceModal_subtext2')}`}
      />
      <CloseModal
        modelOpen={openDeleteComparison}
        closeModal={handleDeleteModal}
        saveValid
        closeText={useTranslate('common.text.cancel')}
        saveText={useTranslate('inst.comp.deleteModal_savetext')}
        title={useTranslate('inst.comp.noitemModal_title')}
        subText={useTranslate('inst.comp.deleteModal_subtext')}
      />
      <ImagePreviewModal
        previewImage={previewImage}
        imageList={previewImageList}
        imgPreviewOpen={imgPreviewOpen}
        onCloseItemModal={() => {
          setImgPreviewOpen(false);
          setPreviewImageList([]);
        }}
      />
    </div>
  );
};

export default InstantComparison;
