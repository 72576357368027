/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import './IoSAddedItems.css';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import SSRIcon from '@ingka/ssr-icon';
import LinkOut from '@ingka/ssr-icon/paths/link-out';
import { useTranslate } from '../../../../../common/services/translationService.js';
import HoverDialog from '../HoverDialog';
import { Item } from '../Items';

const IoSAddedItems = ({
  data, hasFilter, navigateToFC, imageToken,
}: {
    data: any, hasFilter: boolean, navigateToFC: (val: any) => void, imageToken: string
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [imgToken, setImageToken] = useState<any>();
  const [dataVal, setDataVal] = useState<any>();

  useEffect(() => {
    setImageToken(imageToken);
    setDataVal([]);
    setTimeout(() => {
      setDataVal(data);
    }, 200);
  }, [imageToken]);

  const renderComparisonCount = (text: any, value: any) => (
    text > 0 ? (
      <div className="table-number itemPointer itemsComparisonCount">
        {text}
        <SSRIcon className="table-image-right" paths={LinkOut} onClick={() => navigateToFC(value)} />
      </div>
    ) : (
      <div className="table-number">
        {text}
      </div>
    )
  );

  const columns: ColumnsType<Item> = [
    {
      key: 1,
      title: useTranslate('tab.items.state'),
      dataIndex: 'itemState',
      sorter: false,
      showSorterTooltip: false,
      width: 50,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.items.item_name'),
      dataIndex: 'itemName',
      sorter: false,
      showSorterTooltip: false,
      width: 130,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: useTranslate('tab.items.item'),
      dataIndex: 'itemNo',
      sorter: false,
      showSorterTooltip: false,
      width: 50,
      className: 'table-number',
      render: (text: any) => <div className="table-number itemNo-common-design">{text}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: useTranslate('tab.items.type'),
      dataIndex: 'itemType',
      sorter: false,
      showSorterTooltip: false,
      width: 45,
      className: 'table-header-padding compLeftPadding',
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: useTranslate('tab.items.image'),
      dataIndex: 'itemImage',
      className: 'table-header-padding',
      width: 25,
      render: (text: any, value: any) => (
        <HoverDialog
          piaDetails={value}
          imageToken={imgToken}
        />
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 6,
      title: useTranslate('tab.items.comp'),
      dataIndex: 'comparisonCount',
      sorter: false,
      showSorterTooltip: false,
      width: 40,
      className: 'table-number compRightPadding',
      render: (text: any, value: any) => renderComparisonCount(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  useEffect(() => {
    const isAcceptedItems = data?.filter((item: any) => item?.isActive === true);
    const isAcceptedItemsRowKeys = isAcceptedItems.map((item: any) => item?.itemNo);
    setSelectedRowKeys(isAcceptedItemsRowKeys);
  }, [data]);

  const itemCount = () => data?.filter((item: any) => item?.isRuItem !== 1)?.length;

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
    columnTitle: useTranslate('tab.items.select'),
    columnWidth: 25,
    scrollToFirstRowOnChange: true,
    getCheckboxProps: (record: any) => ({
      disabled: true,
      name: record.name,
    }),
  };

  return (
    <Accordion className={`accIoSAddedItems ${hasFilter !== true ? 'mt-18' : null}`}>
      <AccordionItem
        title={`IoS added items (${itemCount()})`}
        id="IosAddedItems"
      >
        <Table
          className="table-striped-rows itemsTable accIoSAddedTable"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataVal}
          pagination={false}
          size="small"
          rowKey={(record: any) => record.itemNo}
          showHeader={data.length > 0}
          scroll={data.length > 0 ? { y: '45vh' } : { y: '' }}
          footer={undefined}
        />
      </AccordionItem>
    </Accordion>
  );
};

export default IoSAddedItems;
