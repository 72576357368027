/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal, {
  ModalBody, ModalHeader, Sheets,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/accordion/dist/style.css';
import React, { useEffect, useRef, useState } from 'react';
import Search from '@ingka/search';
import IcItems from '@ingka/ssr-icon/paths/copy';
import IcPlus from '@ingka/ssr-icon/paths/plus';
import IcTrashCan from '@ingka/ssr-icon/paths/trash-can';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import SelectSkapa, { Option as OptionSkapa } from '@ingka/select';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { useAppDispatch } from '../../../../store/hooks';
import {
  HFB, HFBtree, Item, searchData,
} from '../../createProject/items/Items';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import styles from './ConnectItemPanel.module.css';
import HoverDialog from '../../createProject/items/HoverDialog';
import Pagination from '../../../../common/components/pagination/Pagination';
import {
  isIos,
  isNullEmptyOrUndefined,
  isSu,
} from '../../../../common/services/commonService.js';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import { offlineData } from '../../../../common/configs/offlineData';
import { Units } from '../../instantComparison/InstantComparison';
import { pieces } from '../../../../common/constants/constant';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

const QcConnectItemPanel = ({
  isVisible, connectedItem, isCancel, comparisonObject, compUnit, onClose, setItem, setCompUnit, setIkeaPrice, setIkeaQuntnUnit, setIkeaIsfood, setIkeaFoodDetails,
}: {
  isVisible: boolean,
  connectedItem: Item | null,
  isCancel: boolean,
  comparisonObject: any,
  compUnit:any,
  onClose: () => void,
  setItem: (item: any, record: any) => void,
  setCompUnit: (val: any) => void,
  setIkeaPrice: (val: any) => void,
  setIkeaQuntnUnit: (val: any, dat:any) => void,
  setIkeaIsfood: (val: boolean) => void,
  setIkeaFoodDetails: (val: any) => void,
}) => {
  const defaultSearchData = { ...searchData, IsSalePriceRequired: true };
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const [paginationItemCount, setPaginationItemCount] = useState<number>(1);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchPayload, setSearchPayload] = useState(defaultSearchData);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [disablePieces, setDisablePieces] = useState<boolean>(false);
  const [selectedQuantity, setSelectedQuantity] = useState<string>('');
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [unitListDisplay, setUnitListDisplay] = useState<Array<Units>>([]);
  const [foodHFBs, setFoodHFBs] = useState<Array<string>>([]);
  const itemsConnect = useTranslate('tab.items.connect');
  const itemsRemove = useTranslate('tab.items.remove');
  const { get, post } = useCallApi();
  const [imageToken, setImageToken] = useState<any>();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getHFB = () => {
    get('business-structure', setHFBdata);
  };

  const onUnitSelect = (val: any) => {
    if (val === pieces.code) {
      setIkeaQuntnUnit(connectedItem?.ikeaMultiPack, pieces.code);
    } else if (!isNullEmptyOrUndefined(selectedQuantity) && !isNullEmptyOrUndefined(selectedUnit)) { setIkeaQuntnUnit(selectedQuantity, selectedUnit); }
    setCompUnit(val);
  };

  const fetchUnitCode = () => {
    if (isSu()) {
      return comparisonObject?.suCode;
    }
    const refRuList = localStorage.getItem('refRuList') || '';
    const enumRu = refRuList ? JSON.parse(refRuList).find((item: any) => (item.key === comparisonObject?.ruCode)) : null;
    return enumRu?.value || comparisonObject?.ruCode;
  };

  const fetchUnitCodeForItemPrice = () => {
    if (isSu()) {
      return localStorage.getItem('RuCodeForSu');
    }
    const refRuList = localStorage.getItem('refRuList') || '';
    const enumRu = refRuList ? JSON.parse(refRuList).find((item: any) => (item.key === comparisonObject?.ruCode)) : null;
    return enumRu?.value || comparisonObject?.ruCode;
  };

  const selectNonFoodHfb = (itemData: any) => {
    setUnitListDisplay([pieces]);
    onUnitSelect(pieces.code);
    setDisablePieces(true);
    !isNullEmptyOrUndefined(itemData) && setIkeaQuntnUnit(itemData?.ikeaMultiPack, pieces.code);
  };

  const fetchUnits = (passedItem?: Item | any | null, measureSystem?: any) => {
    dispatch(setLoader(true));
    const payload = {
      ItemType: passedItem?.itemType || null,
      ItemNo: passedItem?.itemNo || null,
      MeasureSystem: measureSystem || null,
    };
    post('get-fooditem-details', payload, (data: any) => {
      const unitList = JSON.parse(localStorage.getItem('unitList') || '');
      const unitData = data?.validUnits;
      const priceData = data?.priceUnit?.content[0];
      setIkeaFoodDetails(data);
      let selUnit = '';
      let selQuantity = '';
      if (!isNullEmptyOrUndefined(data) && !isNullEmptyOrUndefined(unitData)
      && !isNullEmptyOrUndefined(priceData) && !isNullEmptyOrUndefined(unitList)) {
        const updatedUnits: any[] = [];
        unitData.forEach((apiUnit: any) => {
          updatedUnits.push(...unitList.filter((localUnit: any) => apiUnit.toLowerCase() === localUnit.code.toLowerCase()));
        });
        // append pieces
        updatedUnits.unshift(pieces);
        setUnitListDisplay(updatedUnits);
        if (measureSystem.includes('IMP')) {
          selUnit = updatedUnits.find((item) => item.code === priceData.priceUnitTextImperial)?.code || pieces.code;
          selQuantity = (priceData?.priceUnitTypeName === 'Volume' && priceData?.priceUnitTextImperial === 'oz')
            ? 'fl.oz' : priceData?.priceUnitTextImperial || null;
          setIkeaQuntnUnit(selQuantity, selUnit);
        } else {
          selUnit = updatedUnits.find((item) => item.code === priceData.priceUnitTextMetric)?.code || pieces.code;
          selQuantity = priceData.priceUnitFactorMetric;
          setIkeaQuntnUnit(selQuantity, selUnit);
        }
        setSelectedQuantity(selQuantity);
        setSelectedUnit(selUnit);
        onUnitSelect(updatedUnits.find((val) => val.code === compUnit).length !== 0 ? compUnit : selUnit);
        setDisablePieces(false);
      } else {
        selectNonFoodHfb(passedItem);
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: 'Failed to fetch the units' }));
    });
  };

  const fetchWholeUnitsList = () => {
    offlineData.getItem('enumData').then((data: any) => {
      // get the food hfb list
      const foodhfbs = data.find((item: any) => item.key === 'FOOD_HFB_LIST')?.value?.split('|');
      setFoodHFBs(foodhfbs);
      // get the measurement system
      const measSys = data.filter((item: any) => item.groupName === 'RUMeasUnits');
      const filteredMeasSys = measSys.filter((item:any) => item.key === fetchUnitCode());
      const measureSystems: Array<string> = [];
      filteredMeasSys.forEach((sys: any) => measureSystems.push(sys.value));
      if (foodHFBs.length > 0 && !isNullEmptyOrUndefined(connectedItem)) {
        const itemHFB = connectedItem?.paNo.slice(0, 2) || '';
        const foodhfb = foodHFBs?.includes(itemHFB);
        setIkeaIsfood(foodhfb === true);
        if (measureSystems?.length > 0 && foodhfb === true) {
          fetchUnits(connectedItem, measureSystems);
        } else selectNonFoodHfb(connectedItem);
      }
      // get the units
      const units:Array<Units> = [];
      const filteredList = data.filter((item: any) => measureSystems.includes(item.groupName));
      filteredList.length !== 0 && filteredList.forEach((filteredItem: any) => {
        units.push({
          system: filteredItem.groupName,
          code: filteredItem.key,
          unit: filteredItem.value,
        });
      });
      units.unshift(pieces);
      setUnitListDisplay(units);
      localStorage.setItem('unitList', JSON.stringify([...units]));
    });
  };

  useEffect(() => {
    getSasToken();
    if (HFBdata.length === 0) {
      getHFB();
    }
  }, []);

  const clearSearch = () => {
    setSelectedHFBs([]);
    setSearchPayload(defaultSearchData);
  };

  useEffect(() => {
    if (isCancel === true) {
      clearSearch();
    }
  }, [isCancel]);

  useEffect(() => {
    fetchWholeUnitsList();
  }, [connectedItem]);

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(defaultSearchData);
      setHasFilter(checkFilterExist);
      if (checkFilterExist) {
        toggleLoader(true);
        post(
          'search-items',
          searchPayload,
          (response: Item[]) => {
            setSearchResults(response);
            toggleLoader(false);
          },
          (error: any) => {
            toggleLoader(false);
            setSearchResults([]);
          },
        );
      } else {
        toggleLoader(false);
        setSearchResults([]);
      }
      setTriggerShowmore(false);
    }
    didMountRef.current = true;
  }, [searchPayload]);

  const setCommonSearchParams = (searchDat: any) => {
    let searchApiData: any = searchDat;
    searchApiData = {
      ...searchApiData,
      UnitCode: fetchUnitCode(),
      ProjectType: isIos() ? 1 : comparisonObject?.projectType,
    };
    setSearchResults([]);
    setSearchPayload(searchApiData);
  };

  const onSearchChange = (e: any, data: any) => {
    const searchTerm = data?.value || null;
    let searchApiData: any = searchPayload;
    if (searchTerm === null) {
      searchApiData = {
        ...searchPayload,
        ItemName: '',
        ItemNo: '',
      };
    } else {
      let searchCriteria = { ItemName: '', ItemNo: '' };
      const enteredInput = searchTerm.trim()
        .replace(/\./g, '')
        .replace(/-/g, '');
      if (/^CST\d/i.test(enteredInput)) {
        searchCriteria = { ItemName: '', ItemNo: enteredInput };
      } else if (!isNaN(enteredInput) && enteredInput.length === 8) {
        searchCriteria = { ItemName: '', ItemNo: searchTerm.trim() };
      } else {
        searchCriteria = { ItemName: searchTerm.trim(), ItemNo: '' };
      }
      searchApiData = { ...searchPayload, ...searchCriteria };
    }
    setCommonSearchParams(searchApiData);
  };

  const onCheckboxChange = (selection: [], type: string) => {
    let searchApiData: any = searchPayload;
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    setSelectedHFBs(selection);
    if (selection.length !== 0) {
      selection?.map((val: string) => {
        switch (val.length) {
          case 2:
            HFBData.push(val);
            break;
          case 3:
            PRAData.push(val);
            break;
          case 4:
            PAData.push(val);
            break;
          default:
            break;
        }
        return null;
      });
    } else {
      HFBData = []; PRAData = []; PAData = [];
    }
    searchApiData = ({
      ...searchPayload, HfbId: HFBData, PraId: PRAData, PaId: PAData,
    });
    setCommonSearchParams(searchApiData);
  };

  const hfbClick = (isOpen:boolean) => {
    setIsHFBOpened(isOpen);
  };

  const getItemPrice = (connectedItemData: Item) => {
    if (connectedItemData && connectedItemData?.itemType && connectedItemData?.itemNo) {
      dispatch(setLoader(true));
      const itemPricePayload = {
        ItemNos: [`${connectedItemData?.itemType}-${connectedItemData?.itemNo.replaceAll('.', '')}`],
        RuCode: fetchUnitCodeForItemPrice(),
      };
      post('item-price', itemPricePayload, (value: any) => {
        dispatch(setLoader(false));
        if (value[0]?.priceInclTax) {
          setIkeaPrice(value[0]?.priceInclTax || 0);
        }
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const onItemConnect = (item:Item) => {
    setItem(item, comparisonObject);
    getItemPrice(item);
    item?.itemName && dispatch(setToast({ openToast: true, toastLabel: `${item?.itemName} - Item connected` }));
  };

  const onRemoveItem = () => {
    setItem(null, comparisonObject);
    setSelectedQuantity('');
    setSelectedUnit('');
  };

  useEffect(() => {
    if (paginationItemCount > 1) {
      setSearchPayload({ ...searchPayload, NoOfItems: (paginationItemCount * 25).toString() });
    }
  }, [paginationItemCount]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const renderPagination = () => {
    const count = searchResults.length;
    const totalCount = searchResults[0].itemCount;
    return (
      <Pagination
        count={count}
        isLoading={isLoading}
        totalCount={totalCount}
        showMoreContent={showMoreContent}
      />
    );
  };

  const renderItem = (
    index: number,
    item: Item,
    isConnect: boolean,
  ) => (
    <div
      key={item.itemNo}
      className={
      `${isConnect ? styles.itemWrapper : styles.connectedItemWrapper}
      ${index + 1 === searchResults.length ? styles.itemWrapperLastChild : ''}`
      }
    >
      <p className={styles.itemTitle}>
        {item?.itemName}
      </p>
      <div className={styles.itemContent}>
        <div className={styles.itemContentWrapper}>
          {`${item?.itemType}  •  ${item?.itemNo}  •  ${item?.displayPrice}  •`}
          <HoverDialog
            piaDetails={item}
            customClass={styles.imageIcon}
            customHoverClass={styles.hoverCard}
            imageToken={imageToken}
          />
          {item?.isBti === true ? (
            <div style={{ display: 'flex', marginLeft: '24px', marginBottom: '4px' }}>
              <BtiComponent ml />
            </div>
          ) : null}
        </div>
        {isConnect && (
          <div
            className={styles.btConnect}
            onClick={() => onItemConnect(item)}
            onKeyDown={() => onItemConnect(item)}
            tabIndex={index}
            role="button"
          >
            <SSRIcon paths={IcPlus} className={styles.plus} />
            {itemsConnect}
          </div>
        )}
        {!isConnect && (
          <div
            className={styles.btRemove}
            onClick={() => onRemoveItem()}
            onKeyDown={() => onRemoveItem()}
            tabIndex={index}
            role="button"
          >
            <SSRIcon paths={IcTrashCan} />
            {itemsRemove}
          </div>
        )}
      </div>
    </div>
  );

  const renderUnitSelect = () => (
    <SelectSkapa
      id="unitSelection"
      className={styles.icUnitSelect}
      value={compUnit}
      onChange={(e) => onUnitSelect(e.target.value)}
      hintText=""
      disabled={disablePieces}
    >
      {unitListDisplay.map((unit, index) => (
        <OptionSkapa key={`${unit}${index.toString()}`} value={unit.code} name={unit.unit} />
      ))}
    </SelectSkapa>
  );

  return (
    <div>
      <Modal
        className="connectItemPanelModel"
        visible={isVisible}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={onClose}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={null}
          header={
            <ModalHeader ariaCloseTxt="Close button text" title={useTranslate('tab.connectIKEAItems')} />
          }
        >
          <ModalBody>
            {connectedItem !== null ? (
              renderItem(0, connectedItem, false)
            ) : null}
            {connectedItem !== null ? (
              renderUnitSelect()
            ) : null}
            <div style={{ marginBottom: '24px' }} className={connectedItem ? styles.disableFields : ''}>
              <Search
                id="search"
                placeholder={useTranslate('tab.items.search_items')}
                onSearch={onSearchChange}
                onClear={(e, data) => onSearchChange(e, null)}
              />
            </div>
            {HFBdata.length > 0 && (
              <HFBtree
                projectData={HFBdata}
                isHFBOpened={isHFBOpened}
                dropdownClick={hfbClick}
                onCheckboxChange={onCheckboxChange}
                defaultVal={selectedHFBs}
                customClass={connectedItem ? styles.disableFields : ''}
              />
            )}
            <hr className="items-demo-divider__hr-horizontal" />
            {hasFilter === true ? (
              <PillsToolbar
                data={searchPayload}
                hfbData={selectedHFBs}
                onRemoveFilter={(newPayload, newHfbData) => {
                  setSelectedHFBs(newHfbData);
                  setSearchPayload(newPayload);
                }}
                onClearAll={() => clearSearch()}
              />

            ) : null}
            <div className={`${styles.itemsContainer} ${connectedItem ? styles.disableFields : ''}`}>
              {searchResults.length > 0
                ? (
                  searchResults.map(
                    (item: Item, index: number) => renderItem(index, item, true),
                  )
                ) : (
                  <div>
                    <RenderEmpty
                      empText="Connect Items"
                      icon={IcItems}
                      subText
                    />
                  </div>
                )}
              {searchResults.length > 0
                && searchResults[0].itemCount > 20 && renderPagination()}
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );
};

export default QcConnectItemPanel;
