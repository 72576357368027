/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal, {
  ModalBody, ModalHeader, Sheets,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/accordion/dist/style.css';
import React, { useEffect, useRef, useState } from 'react';
import Search from '@ingka/search';
import IcItems from '@ingka/ssr-icon/paths/copy';
import IcPlus from '@ingka/ssr-icon/paths/plus';
import IcTrashCan from '@ingka/ssr-icon/paths/trash-can';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { useAppDispatch } from '../../../../store/hooks';
import {
  HFB, HFBtree, Item, searchData,
} from '../../createProject/items/Items';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import styles from './ConnectItemPanel.module.css';
import HoverDialog from '../../createProject/items/HoverDialog';
import Pagination from '../../../../common/components/pagination/Pagination';
import {
  checkIsCountrySpecific,
  isIos, isNullEmptyOrUndefined, projectType, userRefRu,
} from '../../../../common/services/commonService.js';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import { RetailUnit } from '../../createProject/retailUnitsTab/RetailUnits';
import { offlineData } from '../../../../common/configs/offlineData';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

const ConnectItemPanel = ({
  isVisible, connectedItem, selectedRU, onClose, setConnectedItem,
}: {
  isVisible: boolean,
  connectedItem: Item | null,
  selectedRU: RetailUnit | null,
  onClose: () => void,
  setConnectedItem: React.Dispatch<React.SetStateAction<Item | null>>,
}) => {
  const defaultSearchData = {
    ...searchData,
    IsSalePriceRequired: true,
    IsCountrySpecific: checkIsCountrySpecific(),
  };
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const [paginationItemCount, setPaginationItemCount] = useState<number>(1);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchPayload, setSearchPayload] = useState(defaultSearchData);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const itemsConnect = useTranslate('tab.items.connect');
  const itemsRemove = useTranslate('tab.items.remove');
  const { get, post } = useCallApi();
  const [imageToken, setImageToken] = useState<any>();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getHFB = () => {
    get('business-structure', setHFBdata);
  };

  useEffect(() => {
    getSasToken();
    if (HFBdata.length === 0) {
      getHFB();
    }
  }, []);

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(defaultSearchData);
      setHasFilter(checkFilterExist);
      if (checkFilterExist) {
        toggleLoader(true);
        post(
          'search-items',
          searchPayload,
          (response: Item[]) => {
            setSearchResults(response);
            toggleLoader(false);
          },
          (error: any) => {
            toggleLoader(false);
            setSearchResults([]);
          },
        );
      } else {
        toggleLoader(false);
        setSearchResults([]);
      }
      setTriggerShowmore(false);
    }
    didMountRef.current = true;
  }, [searchPayload]);

  const setCommonSearchParams = (searchDat: any) => {
    let searchApiData: any = searchDat;
    if (isIos()) {
      offlineData.getItem('enumData').then((enumData: any) => {
        if (!isNullEmptyOrUndefined(enumData)) {
          const enumRu = enumData.find((item: any) => (item.groupName === 'RefRuCode' && item.key === selectedRU?.ruCode));
          searchApiData = { ...searchApiData, UnitCode: enumRu?.value || selectedRU?.ruCode };
          searchApiData = (
            { ...searchApiData, ProjectType: 1 });
        } else {
          searchApiData = { ...searchApiData, UnitCode: selectedRU?.ruCode || null };
          searchApiData = (
            { ...searchApiData, ProjectType: 1 });
        }
        setSearchResults([]);
        setSearchPayload(searchApiData);
      });
    } else {
      searchApiData = { ...searchApiData, UnitCode: userRefRu() };
      searchApiData = (
        { ...searchApiData, ProjectType: projectType() });
      setSearchResults([]);
      setSearchPayload(searchApiData);
    }
  };

  const onSearchChange = (e: any, data: any) => {
    const searchTerm = data?.value || null;
    let searchApiData: any = searchPayload;
    if (searchTerm === null) {
      searchApiData = {
        ...searchPayload,
        ItemName: '',
        ItemNo: '',
      };
    } else {
      let searchCriteria = { ItemName: '', ItemNo: '' };
      const enteredInput = searchTerm.trim()
        .replace(/\./g, '')
        .replace(/-/g, '');
      if (/^CST\d/i.test(enteredInput)) {
        searchCriteria = { ItemName: '', ItemNo: enteredInput };
      } else if (!isNaN(enteredInput) && enteredInput.length === 8) {
        searchCriteria = { ItemName: '', ItemNo: searchTerm.trim() };
      } else {
        searchCriteria = { ItemName: searchTerm.trim(), ItemNo: '' };
      }
      searchApiData = { ...searchPayload, ...searchCriteria };
    }
    setCommonSearchParams(searchApiData);
  };

  const onCheckboxChange = (selection: [], type: string) => {
    let searchApiData: any = searchPayload;
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    setSelectedHFBs(selection);
    if (selection.length !== 0) {
      selection?.map((val: string) => {
        switch (val.length) {
          case 2:
            HFBData.push(val);
            break;
          case 3:
            PRAData.push(val);
            break;
          case 4:
            PAData.push(val);
            break;
          default:
            break;
        }
        return null;
      });
    } else {
      HFBData = []; PRAData = []; PAData = [];
    }
    searchApiData = ({
      ...searchPayload, HfbId: HFBData, PraId: PRAData, PaId: PAData,
    });
    setCommonSearchParams(searchApiData);
  };

  const hfbClick = (isOpen:boolean) => {
    setIsHFBOpened(isOpen);
  };

  const onItemConnect = (item:Item) => {
    setConnectedItem(item);
    item?.itemName && dispatch(setToast({ openToast: true, toastLabel: `${item?.itemName} - Item connected` }));
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const onRemoveItem = () => {
    setConnectedItem(null);
  };

  useEffect(() => {
    if (paginationItemCount > 1) {
      setSearchPayload({ ...searchPayload, NoOfItems: (paginationItemCount * 25).toString() });
    }
  }, [paginationItemCount]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const renderPagination = () => {
    const count = searchResults.length;
    const totalCount = searchResults[0].itemCount;
    return (
      <Pagination
        count={count}
        isLoading={isLoading}
        totalCount={totalCount}
        showMoreContent={showMoreContent}
      />
    );
  };

  const renderItem = (
    index: number,
    item: any,
    isConnect: boolean,
  ) => (
    <div
      key={item.itemNo}
      className={
      `${isConnect ? styles.itemWrapper : styles.connectedItemWrapper}
      ${index + 1 === searchResults.length ? styles.itemWrapperLastChild : ''}`
      }
    >
      <p className={styles.itemTitle}>
        {item?.itemName}
      </p>
      <div className={styles.itemContent}>
        <div className={styles.itemContentWrapper}>
          {`${item?.itemType}  •  ${item?.itemNo}  •  ${!isNullEmptyOrUndefined(item?.displayPrice) ? item?.displayPrice
            : !isNullEmptyOrUndefined(item?.priceInclTax)
              ? `${!isNullEmptyOrUndefined(item?.currency)
                ? `${item?.currency}`
                : ''} ${item?.priceInclTax}`
              : ''}  •`}
          <HoverDialog
            piaDetails={item}
            customClass={styles.imageIcon}
            customHoverClass={styles.hoverCard}
            imageToken={imageToken}
          />
          {item?.isBti === true ? (
            <div style={{ display: 'flex', marginLeft: '24px', marginBottom: '4px' }}>
              <BtiComponent ml />
            </div>
          ) : null}
        </div>
        {isConnect && (
          <div
            className={styles.btConnect}
            onClick={() => onItemConnect(item)}
            onKeyDown={() => onItemConnect(item)}
            tabIndex={index}
            role="button"
          >
            <SSRIcon paths={IcPlus} className={styles.plus} />
            {itemsConnect}
          </div>
        )}
        {!isConnect && (
          <div
            className={styles.btRemove}
            onClick={() => onRemoveItem()}
            onKeyDown={() => onRemoveItem()}
            tabIndex={index}
            role="button"
          >
            <SSRIcon paths={IcTrashCan} />
            {itemsRemove}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        className="connectItemPanelModel"
        visible={isVisible}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={onClose}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={null}
          header={
            <ModalHeader ariaCloseTxt="Close button text" title={useTranslate('tab.connectIKEAItems')} />
          }
        >
          <ModalBody>
            {connectedItem !== null ? (
              renderItem(0, connectedItem, false)
            ) : null}
            <div style={{ marginBottom: '24px' }} className={connectedItem ? styles.disableFields : ''}>
              <Search
                id="search"
                placeholder={useTranslate('tab.items.search_items')}
                onSearch={onSearchChange}
                onClear={(e, data) => onSearchChange(e, null)}
              />
            </div>
            {HFBdata.length > 0 && (
              <HFBtree
                projectData={HFBdata}
                isHFBOpened={isHFBOpened}
                dropdownClick={hfbClick}
                onCheckboxChange={onCheckboxChange}
                defaultVal={selectedHFBs}
                customClass={connectedItem ? styles.disableFields : ''}
              />
            )}
            <hr className="items-demo-divider__hr-horizontal" />
            {hasFilter === true ? (
              <PillsToolbar
                data={searchPayload}
                hfbData={selectedHFBs}
                onRemoveFilter={(newPayload, newHfbData) => {
                  setSelectedHFBs(newHfbData);
                  setSearchPayload(newPayload);
                }}
                onClearAll={() => {
                  setSelectedHFBs([]);
                  setSearchPayload(defaultSearchData);
                }}
              />

            ) : null}
            <div className={`${styles.itemsContainer} ${connectedItem ? styles.disableFields : ''}`}>
              {searchResults.length > 0
                ? (
                  searchResults.map(
                    (item: Item, index: number) => renderItem(index, item, true),
                  )
                ) : (
                  <RenderEmpty
                    empText="Connect Items"
                    icon={IcItems}
                    subText
                  />
                )}
              {searchResults.length > 0
                && searchResults[0].itemCount > 20 && renderPagination()}
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );
};

export default ConnectItemPanel;
