/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import CardRight from '@ingka/ssr-icon/paths/chevron-right-small';
import styles from './ProjectDetails.module.css';
import AppBarMobile from '../../../../common/components/appBarMobile/AppBarMobile';
import ItemDetailsMobile from '../itemDetailsMobile/ItemDetailsMobile';
import { offlineData } from '../../../../common/configs/offlineData';
import { isRu } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

const ProjectDetails = () => {
  const [project, setProject] = useState<any>({});
  const projectItems: any = isRu()
  ? project?.projectDetails?.viewCProjectItems
  : project?.suProjectDetails?.sellingUnitProjectItemViewDto;
  const [openItemModal, setOpenItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const onItemClick = (item: any) => {
    setSelectedItem(item);
    setOpenItemModal(true);
  };

  useEffect(() => {
    offlineData.getItem('selectedProject').then((data) => {
     setProject(data);
    });
  }, []);

  const onCloseItemModal = () => setOpenItemModal(false);

  return (
    <div className={styles.cardDetailsContainer}>
      <AppBarMobile
        source="other"
        title={project?.projectName}
      />
      <div className={styles.cardDetailsBasicWrapper}>
        <div className={styles.cardDetailsDate}>
          <span className={styles.cardDetailsTextTitle}>Start Date:&nbsp;</span>
          {project?.projectStartDate}
        </div>
        <div className={styles.cardDetailsDate}>
          <span className={styles.cardDetailsTextTitle}>End Date:&nbsp;</span>
          {project?.projectEndDate}
        </div>
        {project?.projectDetails?.viewCProjectDetails.message ? (
          <div className={styles.cardDetailsMessage}>
            <span className={styles.cardDetailsTextTitle}>Message </span>
            <span className={styles.cardDetailsTextContent}>
              {project?.projectDetails?.viewCProjectDetails.message}
            </span>
          </div>
        ) : null}
        <div className={styles.cardDetailsItem}>
          <span className={styles.cardDetailsTextTitle}>Items </span>
          <div className={styles.cardDetailsItemsList}>
            {projectItems?.map((item: any) => (
              <div
                key={item.itemNo}
                className={styles.cardDetailsItemWrapper}
                role="button"
                tabIndex={0}
                onClick={() => onItemClick(item)}
                onKeyDown={() => onItemClick(item)}
              >
                <div className={styles.cardDetailsItemTextWrapper}>
                  <p className={styles.cardDetailsItemTitle}>
                    {item?.itemName}
                  </p>
                  <div className={styles.projectDetailsSubTitleSontainer}>
                    <p className={styles.cardDetailsItemContent}>
                      {`${item?.paNo}
                      •  ${item?.itemNo}
                        •  ${item?.itemType}`}
                    </p>
                    {item?.isBti === true ? (
                      <div style={{ display: 'flex', marginLeft: '8px', alignItems: 'center' }}>
                        <p className={styles.cardDetailsItemContent}>{'  •'}</p>
                        <BtiComponent ml />
                      </div>
                  ) : null}
                  </div>
                </div>
                <SSRIcon paths={CardRight} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ItemDetailsMobile
        openItemModal={openItemModal}
        selectedItem={selectedItem}
        onCloseItemModal={onCloseItemModal}
      />
    </div>
  );
};

export default ProjectDetails;
